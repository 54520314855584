$app-sidebar-width: 300px;
// ANIMATION - sidebar slide out
@keyframes appSidebarEndMobileSlideOutLeft {
  from {
    left: 0;
  }
  to {
    left: -$app-sidebar-width;
  }
}
@keyframes appSidebarEndMobileSlideOutRight {
  from {
    right: 0;
  }
  to {
    right: -$app-sidebar-width;
  }
}

// ANIMATION - wide sidebar slide out
@keyframes appSidebarEndWideMobileSlideOutLeft {
  from {
    left: 0;
  }
  to {
    left: -$app-sidebar-width-wide;
  }
}
@keyframes appSidebarEndWideMobileSlideOutRight {
  from {
    right: 0;
  }
  to {
    right: -$app-sidebar-width-wide;
  }
}

// ANIMATION - remove mobile backdrop
@keyframes appSidebarEndMobileRemoveBackdrop {
  from {
    background: $app-sidebar-bg-mobile-backdrop;
  }
  to {
    background: transparent;
  }
}

.app-sidebar-end-mobile-closed {
  @include media-breakpoint-down(md) {
    & .app-sidebar-end {
      &.app-sidebar,
      &.app-sidebar-bg {
        @if $enable-rtl {
          right: auto;
          left: -$app-sidebar-width;
          animation: appSidebarEndMobileSlideOutLeft 0.2s ease;
        } @else {
          right: -$app-sidebar-width;
          left: auto;
          animation: appSidebarEndMobileSlideOutRight 0.2s ease;
        }
      }
      &.app-sidebar-mobile-backdrop {
        display: block;
        animation: appSidebarEndMobileRemoveBackdrop 0.2s ease;
        background: none;
      }
    }
    &.app-with-wide-sidebar {
      & .app-sidebar-end {
        &.app-sidebar,
        &.app-sidebar-bg {
          @if $enable-rtl {
            left: -$app-sidebar-width-wide;
            animation: appSidebarEndWideMobileSlideOutLeft 0.2s ease;
          } @else {
            right: -$app-sidebar-width-wide;
            animation: appSidebarEndWideMobileSlideOutRight 0.2s ease;
          }
        }
      }
    }
  }
}
