.app-with-two-sidebar {
  & .app-header {
    & .navbar-toggle.pull-start {
      & + .navbar-header {
        justify-content: center;
        margin: 0;
      }
    }
  }
  & .app-sidebar-end {
    &.app-sidebar,
    &.app-sidebar-bg {
      @if $enable-rtl {
        right: auto;
        left: -$app-sidebar-width;
      } @else {
        right: -$app-sidebar-width;
        left: auto;
      }
    }
  }
  &.app-with-wide-sidebar {
    & .app-sidebar-end {
      &.app-sidebar,
      &.app-sidebar-bg {
        @if $enable-rtl {
          left: -$app-sidebar-width-wide;
        } @else {
          right: -$app-sidebar-width-wide;
        }
      }
    }
  }
  &.app-sidebar-minified {
    & .app-sidebar-end {
      &.app-sidebar,
      &.app-sidebar-bg {
        width: $app-sidebar-width;
      }
    }
    &.app-with-wide-sidebar {
      & .app-sidebar-end {
        &.app-sidebar,
        &.app-sidebar-bg {
          width: $app-sidebar-width-wide;
        }
      }
    }
  }
}
