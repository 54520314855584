.widget-chart {
  position: relative;
  margin-bottom: rem(20px);
  border-radius: $border-radius;
  overflow: hidden;

  @include media-breakpoint-up(sm) {
    display: flex;
  }

  & .chart-title {
    margin: rem(2px) rem(5px) rem(5px);
    color: $white;
    font-weight: 600 !important;
    font-size: rem(16px) !important;
    line-height: rem(20px);

    & small {
      display: block;
      margin-top: rem(3px);
      color: lighten($dark, 45%);
      font-weight: normal;
      font-size: rem(12px);
      line-height: rem(16px);
    }
  }
  & .widget-chart-content {
    flex: 1;
    padding: rem(15px);

    & + .widget-chart-sidebar {
      @include media-breakpoint-up(md) {
        border-radius: 0 $border-radius $border-radius 0;
      }
      @include media-breakpoint-down(xs) {
        border-radius: 0 0 $border-radius $border-radius;
      }
    }
  }
  & .widget-chart-full-width {
    margin: 0 rem(-15px);
  }
  & .widget-chart-sidebar {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: rem(15px) rem(15px);

    @include media-breakpoint-up(md) {
      width: rem(230px);
    }
    @include media-breakpoint-only(sm) {
      width: rem(200px);
    }

    & .chart-number {
      color: $white;
      font-weight: 600;
      font-size: rem(24px);
      line-height: rem(28px);

      & small {
        display: block;
        margin-top: rem(3px);
        color: lighten($dark, 45%);
        font-weight: normal;
        font-size: rem(12px);
        line-height: rem(16px);
      }
    }
    & .chart-legend {
      margin: 0;
      padding: 0;
      font-weight: 600;
      font-size: rem(12px);
      list-style-type: none;

      & > li {
        color: $white;

        & span {
          margin-left: rem(5px);
          color: lighten($dark, 45%);
          font-weight: normal;
        }
        & + li {
          margin-top: rem(5px);
        }
      }
    }
  }
}
.widget-chart-info {
  color: var(--#{$prefix}component-color);

  & .widget-chart-info-title {
    margin: 0 0 rem(5px);
    font-size: rem(14px);
  }
  & .widget-chart-info-desc {
    margin-bottom: rem(20px);
    color: rgba(var(--#{$prefix}component-color-rgb), 0.5);
    font-size: rem(11px);
  }
  & .widget-chart-info-progress {
    margin-bottom: rem(5px);
    font-size: rem(11px);

    & .progress {
      margin-bottom: 0;
    }
  }
}
.widget-chart-content {
  background: var(--#{$prefix}light);
  padding: rem(10px);
}
.dark-mode {
  & .widget-chart-content {
    background: darken($dark, 7.5%);
  }
  & .widget-chart-info {
    color: lighten($dark, 60%);

    & .widget-chart-info-desc {
      color: lighten($dark, 45%);
    }
  }
  & .nvd3 {
    text {
      fill: rgba($white, 0.75);
    }
    & .nv-axis {
      & line,
      & path {
        stroke: rgba($white, 0.15);
      }
    }
  }
}
