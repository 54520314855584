.btn {
  &.btn-icon {
    border: none;
    border-radius: 28px;
    padding: 0;
    width: rem(28px);
    height: rem(28px);
    font-size: rem(14px);
    line-height: rem(28px);
    text-align: center;

    &.btn-xs {
      width: rem(16px);
      height: rem(16px);
      font-size: rem(8px);
      line-height: rem(16px);
    }
    &.btn-sm {
      width: rem(22px);
      height: rem(22px);
      font-size: rem(11px);
      line-height: rem(22px);
    }
    &.btn-lg {
      width: rem(34px);
      height: rem(34px);
      font-size: rem(17px);
      line-height: rem(34px);
    }
  }
  &.btn-xs {
    padding: rem(2px) rem(6px);
    font-size: $font-size-sm;
  }
  &[class*="btn-outline-"]:not(.btn-yellow):not(.btn-white) {
    &:hover {
      color: $white;
    }
  }
}

.btn-theme {
  --#{$prefix}btn-bg: var(--#{$prefix}app-theme);
  --#{$prefix}btn-color: var(--#{$prefix}app-theme-color);
  --#{$prefix}border-color: var(--#{$prefix}app-theme);

  --#{$prefix}btn-hover-color: var(--#{$prefix}app-theme-hover-color);
  --#{$prefix}btn-hover-bg: var(--#{$prefix}app-theme-hover);
  --#{$prefix}btn-hover-border-color: var(
    --#{$prefix}app-theme-hover-border-color
  );
  --#{$prefix}btn-focus-shadow-rgb: var(--#{$prefix}app-theme-rgb);

  --#{$prefix}btn-active-color: var(--#{$prefix}app-theme-hover-color);
  --#{$prefix}btn-active-bg: var(--#{$prefix}app-theme-hover);
  --#{$prefix}btn-active-border-color: var(
    --#{$prefix}app-theme-hover-border-color
  );
  --#{$prefix}btn-active-shadow: inset 0 3px 5px
    rgba(var(--#{$prefix}app-theme-rgb), 0.125);
  --#{$prefix}btn-active-color: var(--#{$prefix}app-theme-disabled-color);
  --#{$prefix}btn-active-bg: var(--#{$prefix}app-theme-disabled);
  --#{$prefix}btn-active-border-color: var(
    --#{$prefix}app-theme-disabled-border-color
  );
}
.btn-outline-theme {
  border-color: var(--#{$prefix}app-theme);
  color: var(--#{$prefix}app-theme);

  &:hover,
  &.active {
    border-color: var(--#{$prefix}app-theme);
    background-color: var(--#{$prefix}app-theme);
  }
  &.disabled,
  &:disabled {
    color: var(--#{$prefix}app-theme);
  }
  &:focus,
  &:active,
  &.active:focus,
  &:active:focus {
    @include box-shadow(
      0 0 0 0.25rem rgba(#{var(--#{$prefix}app-theme-rgb)}, 0.5)
    );
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    $bg: $value;
    $border: $value;
    $textColor: $white;
    $hoverBg: shade-color($value, 25%);
    $hoverBorder: shade-color($value, 25%);
    $hoverTextColor: $white;
    $activeBg: shade-color($value, 25%);
    $activeBorder: shade-color($value, 25%);
    $activeTextColor: $white;
    $disabledBg: tint-color($value, 25%);
    $disabledBorder: tint-color($value, 25%);
    $disabledTextColor: $white;

    @if $color == "yellow" or $color == "default" or $color == "white" {
      $textColor: $gray-900;
      $hoverTextColor: $gray-900;
      $activeTextColor: $gray-900;
      $disabledTextColor: $gray-900;
    }
    @if $color == "default" {
      $hoverBg: $gray-400;
      $hoverBorder: $gray-400;
      $activeBg: $gray-400;
      $activeBorder: $gray-400;
    }
    @if $color == "white" {
      $border: $gray-400;
      $hoverBg: $gray-200;
      $hoverBorder: $gray-500;
      $activeBg: $gray-200;
      $activeBorder: $gray-500;
      $disabledBg: tint-color($gray-200, 25%);
      $disabledBorder: tint-color($gray-500, 25%);
    }

    @include button-variant(
      $bg,
      $border,
      $textColor,
      $hoverBg,
      $hoverBorder,
      $hoverTextColor,
      $activeBg,
      $activeBorder,
      $activeTextColor,
      $disabledBg,
      $disabledBorder,
      $disabledTextColor
    );

    .btn-group > .btn + &,
    .input-group > .btn + &,
    .input-group > .btn + .dropdown-menu + & {
      @if $enable-rtl {
        border-right: 1px solid $hoverBorder;
      } @else {
        border-left: 1px solid $hoverBorder;
      }
    }
  }
}
