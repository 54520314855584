.ui-widget {
  font-family: inherit;

  &.ui-autocomplete {
    box-shadow: $box-shadow;
    border: none;
    border-radius: 0 0 $border-radius $border-radius;
    background-color: var(--#{$prefix}component-dropdown-bg);
    padding: $spacer * 0.5 0;
    font-size: $font-size-base;

    & .ui-menu-item-wrapper {
      padding: $nav-link-padding-y $nav-link-padding-x;
      color: $dropdown-link-color;
      font-weight: $font-weight-bold;

      &.ui-state-active {
        margin: 0;
        border: none;
        border-radius: 0;
        background: var(--#{$prefix}component-dropdown-hover-bg);
        color: var(--#{$prefix}component-hover-color);
      }
    }
  }
}
