.dropzone {
  border: 2px dashed var(--#{$prefix}component-border-color) !important;
  background: none !important;

  & .dz-message {
    margin: $spacer * 3 0 !important;
    color: inherit !important;
    font-weight: $font-weight-light !important;
    font-size: $h1-font-size !important;
    text-align: center !important;

    & .dz-note {
      display: block !important;
      margin-top: $spacer;
      color: $gray-500;
      font-weight: normal !important;
      font-size: $font-size-lg !important;
    }
  }

  & .dz-preview {
    & .dz-image {
      border-radius: $border-radius-lg !important;
    }
    & .dz-error-message {
      background: $danger !important;

      &:after {
        border-bottom-color: $danger !important;
      }
    }
    & .dz-details {
      & .dz-size,
      & .dz-filename {
        & span {
          background: none !important;
        }
      }
    }
    & .dz-error-mark {
      z-index: 1020 !important;

      &:before {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius * 10;
        background: lighten($dark, 60%);
        width: rem(54px);
        height: rem(54px);
        content: "\f00d";
        color: lighten($dark, 30%);
        font-size: rem(28px);

        @include fontawesome();
      }
      &:hover,
      &:focus {
        &:before {
          background: lighten($dark, 45%);
          color: lighten($dark, 15%);
        }
      }
      & svg {
        display: none !important;
      }
    }
    &.dz-file-preview {
      & .dz-image {
        border-radius: $border-radius !important;
        background: $light !important;
      }
    }
    &.dz-image-preview {
      background: none !important;
    }
  }
  &.dz-clickable {
    &:hover,
    &:focus {
      background: var(--#{$prefix}light) !important;
    }

    & .dz-message {
      cursor: pointer;

      & * {
        cursor: pointer;
      }
    }

    &.dz-started {
      &:hover,
      &:focus {
        background: none !important;
      }
    }
  }
}

.inverse-mode {
  & .dropzone {
    border-color: rgba($white, 0.25) !important;

    & .dz-clickable {
      &:hover,
      &:focus {
        background: rgba($white, 0.15) !important;
      }
    }
    & .dz-message {
      color: inherit !important;

      & .dz-note {
        color: rgba($white, 0.75) !important;
      }
    }
  }
}
