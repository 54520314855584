.note {
  display: flex;
  position: relative;
  flex: 1;
  margin-bottom: rem(20px);
  border-color: var(--#{$prefix}alert-border-color);
  border-radius: $border-radius;
  background: var(--#{$prefix}alert-bg);
  color: var(--#{$prefix}alert-color);

  @if $enable-rtl {
    border-right: rem(3px) solid;
  } @else {
    border-left: rem(3px) solid;
  }

  & .note-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(80px);
    font-size: rem(56px);
  }
  & .note-content {
    flex: 1;
    padding: rem(15px);

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      color: inherit;
    }
  }
  &.note-with-end-icon {
    @if $enable-rtl {
      border-right: none;
      border-left: rem(3px) solid;
    } @else {
      border-right: rem(3px) solid;
      border-left: none;
    }
  }
}
