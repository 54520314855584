body {
  & .daterangepicker {
    z-index: 1020;
    box-shadow: $box-shadow;
    border: none;
    border-radius: $border-radius-lg;
    background-color: var(--#{$prefix}component-dropdown-bg);
    font-family: inherit;

    @if $enable-rtl {
      direction: rtl;
      text-align: right;
    }
    @include media-breakpoint-down(md) {
      width: rem(300px);
    }

    &:before {
      display: none;
    }
    &:after {
      border-bottom-color: var(--#{$prefix}component-dropdown-bg);
    }

    & .drp-calendar {
      padding: $spacer;
      max-width: inherit;

      @include media-breakpoint-down(xl) {
        float: none;
      }

      & .calendar-table {
        border-color: var(--#{$prefix}component-dropdown-bg);
        background-color: var(--#{$prefix}component-dropdown-bg);

        & table {
          & thead {
            & tr {
              & th {
                border: none;
                padding: $spacer * 0.5;
                width: auto;
                min-width: auto;
                height: auto;
                color: var(--#{$prefix}component-color);
                font-weight: $font-weight-bolder;
                line-height: 1;

                &.prev,
                &.next {
                  & span {
                    border-color: var(--#{$prefix}component-color);
                  }
                  &:hover,
                  &:focus {
                    background: #{rgba(
                        var(--#{$prefix}component-color-rgb),
                        0.2
                      )};
                    color: var(--#{$prefix}component-color);
                  }
                }
                &.month {
                  & .yearselect,
                  & .monthselect {
                    transition: all 0.2s linear;
                    border-color: var(
                      --#{$prefix}component-dropdown-border-color
                    );
                    border-radius: $border-radius;
                    background: var(--#{$prefix}component-dropdown-bg);
                    color: var(--#{$prefix}component-color);

                    &:focus {
                      outline: none;
                      box-shadow: $input-focus-box-shadow;
                      border-color: $input-focus-border-color;
                    }
                  }
                }
              }
              &:last-child {
                & th {
                  padding-bottom: $spacer * 0.5;
                }
              }
            }
          }
          & tbody {
            & tr {
              & td {
                border: none;
                padding: $spacer * 0.65;
                width: auto;
                min-width: auto;
                height: auto;
                color: var(--#{$prefix}component-color);
                font-weight: $font-weight-bold;
                line-height: 1;

                &.available {
                  border-radius: $border-radius;

                  &:hover,
                  &:focus {
                    background: var(--#{$prefix}component-dropdown-hover-bg);
                  }
                  &.in-range {
                    border-radius: 0;
                    background: tint-color($component-active-bg, 85%);
                    color: var(--#{$prefix}component-color);

                    .dark-mode & {
                      background: shade-color($component-active-bg, 50%);

                      &.active {
                        background: $component-active-bg;
                      }
                    }
                  }
                  &.active {
                    background: $component-active-bg;
                    color: $white;
                  }
                  &.start-date {
                    border-radius: $border-radius 0 0 $border-radius;
                  }
                  &.end-date {
                    border-radius: 0 $border-radius $border-radius 0;
                  }
                }
                &.off {
                  background: none;
                  color: var(--#{$prefix}component-disabled-color);
                }
              }
            }
          }
        }
      }
      &.left {
        padding: $spacer * 0.5 !important;

        & .calendar-table {
          padding: 0 !important;
        }
      }
      &.right {
        border-left: 1px solid var(--#{$prefix}component-dropdown-border-color);
        padding: $spacer * 0.5 !important;

        @include media-breakpoint-down(xl) {
          border-top: 1px solid var(--#{$prefix}component-dropdown-border-color);
          border-left: none;
        }
      }
    }
    & .drp-buttons {
      border-color: var(--#{$prefix}component-dropdown-border-color);
      padding: $spacer * 0.5;

      @if $enable-rtl {
        text-align: left;
      }
      @include media-breakpoint-down(xl) {
        text-align: center;
      }

      & .btn {
        margin: 0;
        min-width: rem(80px);
        font-weight: $font-weight-bold;

        & + .btn {
          @if $enable-rtl {
            margin-right: $spacer * 0.5;
            margin-left: 0;
          } @else {
            margin-right: 0;
            margin-left: $spacer * 0.5;
          }
        }
      }
      & .drp-selected {
        color: $gray-600;
        font-weight: $font-weight-bold;

        @if $enable-rtl {
          margin-right: 0;
          margin-left: $spacer * 0.5;
        }
        @include media-breakpoint-down(xl) {
          display: block;
          margin-bottom: $spacer * 0.5;
          padding: 0;
        }
      }
    }
    &.show-ranges.ltr,
    &.show-ranges.rtl {
      & .ranges {
        display: block;
        position: relative;

        @if $enable-rtl {
          float: right;
        }
        @include media-breakpoint-down(xl) {
          float: none;
          margin: 0;
          border-bottom: 1px solid
            var(--#{$prefix}component-dropdown-border-color);
          padding: rem(10px);
          max-width: rem(340px);
          overflow: scroll;

          @if $enable-rtl {
            float: none;
          }
        }

        & ul {
          @include media-breakpoint-down(xl) {
            display: flex;
            flex-wrap: nowrap;
            padding-right: $spacer;
            width: auto;
            white-space: nowrap;
          }

          & li {
            color: rgba(var(--#{$prefix}component-color-rgb), 0.75);
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(xl) {
              margin-right: $spacer !important;
              border-radius: $border-radius;
            }

            &:hover,
            &:focus {
              background: var(--#{$prefix}component-dropdown-hover-bg);
              color: var(--#{$prefix}component-hover-color);
            }
            &.active {
              margin-right: rem(-1px);
              background: $component-active-bg;
              color: $white;
            }
          }
        }
      }
      & .drp-calendar {
        &.left {
          border-left: 1px solid
            var(--#{$prefix}component-dropdown-border-color);

          @if $enable-rtl {
            border-left: none !important;
          }
          @include media-breakpoint-down(xl) {
            border-left: none;
          }
        }
        &.right {
          @if $enable-rtl {
            border-right: 1px
              solid
              var(--#{$prefix}component-dropdown-border-color);
          }
          @include media-breakpoint-down(xl) {
            @if $enable-rtl {
              border-right: none;
            }
          }
        }
      }
    }
  }
}
