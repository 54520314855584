.tab-overflow {
  position: relative;
  flex: 1;
  border-radius: $border-radius-sm $border-radius-sm 0 0;
  overflow: hidden;

  & .nav {
    &.nav-tabs {
      display: table;
      margin: 0;
      border-radius: $border-radius $border-radius 0 0;
      white-space: nowrap;

      & > li {
        display: table-cell;
        float: none;

        & > a {
          margin-left: rem(-1px);

          @if $enable-rtl {
            margin-right: rem(-1px);
            margin-left: 0;
          }
        }
      }
      & .prev-button {
        display: none !important;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 10;
        transition: all 0.2s linear;
        box-shadow: 0 0 15px rgba($dark, 0.25);

        @if $enable-rtl {
          right: 0;
          left: auto;
        }

        & a {
          margin: 0;
          border-radius: $border-radius 0 0;
          background: $light;
        }
      }
      & .next-button {
        display: none !important;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        z-index: 10;
        transition: all 0.2s linear;
        box-shadow: 0 0 15px rgba($dark, 0.25);
        border-top-left-radius: 0;

        @if $enable-rtl {
          right: auto;
          left: 0;
        }

        & a {
          margin: 0;
          border-radius: 0 $border-radius 0 0;
          background: $light;
        }
      }

      &.nav-tabs-inverse {
        & > li {
          & > a {
            background: none;

            &.active {
              background: $white;
              color: $dark;
            }
          }
        }
        & .next-button,
        & .prev-button {
          box-shadow: 0 0 15px $black;

          & + li {
            & > a {
              &.active {
                border-radius: 0 $border-radius 0 0;
              }
            }
          }
          & > a {
            background: $dark-darker;
          }
        }
      }
    }
  }

  &.overflow-right {
    & .nav {
      &.nav-tabs {
        & .next-button {
          display: block !important;
          opacity: 1;
          background: none;
        }
      }
    }
  }
  &.overflow-left {
    & .nav {
      &.nav-tabs {
        & .prev-button {
          display: block !important;
          opacity: 1;
          background: none;
        }
      }
    }
  }
}
