.file-tree {
  position: relative;

  & .file-node {
    position: relative;

    @if $enable-rtl {
      padding-right: rem-default(15px);
    } @else {
      padding-left: rem-default(15px);
    }

    & .file-link {
      display: flex;
      position: relative;
      border-radius: $border-radius;
      padding: $spacer * 0.25 $spacer * 0.25;
      color: var(--#{$prefix}body-text);
      text-decoration: none;

      & > * {
        &:hover,
        &:focus {
          opacity: 0.75;
        }
      }

      & .file-info {
        display: flex;
        flex: 1;
      }
      & .file-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(20px);
        font-size: $font-size-lg;

        @if $enable-rtl {
          margin-left: $spacer * 0.35;
        } @else {
          margin-right: $spacer * 0.35;
        }
      }
      & .file-text {
        flex: 1;
      }
    }
    &.has-sub {
      & > .file-link {
        & .file-arrow:before {
          display: flex;
          position: absolute;
          top: 50%;
          justify-content: center;
          margin-top: $font-size-base * $line-height-base * -0.5;
          width: rem(12px);
          content: "\2b";

          @include fontawesome();
          @if $enable-rtl {
            right: rem(-12px);
          } @else {
            left: rem(-12px);
          }
        }
      }
      &.expand {
        & > .file-tree {
          display: block;
        }
        & > .file-link {
          & .file-arrow:before {
            content: "\f068";
          }
        }
      }
    }
    &.selected {
      & > .file-link {
        font-weight: $font-weight-bold;

        & > .file-info {
          opacity: 1;
        }
      }
    }
  }
  & .file-tree {
    display: none;
  }
}

.file-manager {
  --#{$prefix}file-manager-sidebar-width: #{rem(250px)};
  --#{$prefix}file-manager-mobile-sidebar-margin: #{rem(-250px)};

  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;

  & .file-manager-toolbar {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--#{$prefix}border-color);
    background: var(--#{$prefix}light);
    padding: $spacer * 0.25;

    @include media-breakpoint-down(lg) {
      flex-wrap: nowrap;
      overflow: scroll;
      white-space: nowrap;
    }
  }
  & .file-manager-container {
    display: flex;
    position: relative;
    flex: 1;
    overflow: hidden;

    & .file-manager-sidebar {
      display: flex;
      flex-direction: column;
      width: var(--#{$prefix}file-manager-sidebar-width);
      min-width: var(--#{$prefix}file-manager-sidebar-width);

      @include media-breakpoint-down(lg) {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 10;
        transition: $transition-base;
        background: var(--#{$prefix}component-bg);

        @if $enable-rtl {
          right: var(--#{$prefix}file-manager-mobile-sidebar-margin);
        } @else {
          left: var(--#{$prefix}file-manager-mobile-sidebar-margin);
        }
      }

      & .file-manager-sidebar-mobile-toggler {
        position: absolute;
        top: $spacer;
        width: $spacer * 2;

        @if $enable-rtl {
          right: 100%;
        } @else {
          left: 100%;
        }
        & .btn {
          transition: $transition-base;
          box-shadow: $box-shadow;
          border-radius: 0;
          background: var(--#{$prefix}component-bg);
          padding: $spacer * 0.5 $spacer * 0.65;
          color: var(--#{$prefix}component-color);

          @if $enable-rtl {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
          } @else {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
          }
        }
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      & .file-manager-sidebar-content {
        flex: 1;
        overflow: hidden;
      }
      & .file-manager-sidebar-footer {
        border-top: 1px solid var(--#{$prefix}border-color);
        padding: $spacer * 0.5 $spacer;
      }
    }
    & .file-manager-content {
      flex: 1;

      @include media-breakpoint-up(lg) {
        @if $enable-rtl {
          border-right: 1px solid var(--#{$prefix}border-color);
        } @else {
          border-left: 1px solid var(--#{$prefix}border-color);
        }
      }
      @include media-breakpoint-down(md) {
        flex-wrap: nowrap;
        overflow: scroll;
      }
    }
  }
  &.file-manager-sidebar-mobile-toggled {
    & .file-manager-sidebar {
      @if $enable-rtl {
        right: 0;
      } @else {
        left: 0;
      }
      @include media-breakpoint-down(lg) {
        box-shadow: $box-shadow-lg;
      }
      & .file-manager-sidebar-mobile-toggler {
        & .btn {
          @include media-breakpoint-down(lg) {
            box-shadow: none;
          }
        }
      }
    }
  }
}
