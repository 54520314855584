.form-horizontal {
  &.form-bordered {
    & .form-group {
      margin: 0;
      border-bottom: 1px solid
        rgba(var(--#{$prefix}component-border-color-rgb), 0.5);

      & .col-form-label {
        display: flex;
        align-items: center;
        padding: $spacer;

        @include media-breakpoint-down(md) {
          padding-bottom: $spacer * 0.5;
        }

        & + div {
          @if $enable-rtl {
            border-right: 1px
              solid
              rgba(var(--#{$prefix}component-border-color-rgb), 0.5);
          } @else {
            border-left: 1px
              solid
              rgba(var(--#{$prefix}component-border-color-rgb), 0.5);
          }
          @include media-breakpoint-down(md) {
            padding-top: 0;

            @if $enable-rtl {
              border-right: none;
            } @else {
              border-left: none;
            }
          }
        }
      }
      & > div {
        padding: $spacer;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
