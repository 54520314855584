$theme-panel-width: rem(260px);

.theme-panel {
  position: fixed;
  top: 0;
  right: -$theme-panel-width;
  bottom: 0;
  z-index: $app-theme-panel-zindex;
  transition: right 0.2s linear;
  border-radius: $border-radius 0 0 $border-radius;
  background: var(--#{$prefix}component-bg);
  width: $theme-panel-width;

  @if $enable-rtl {
    right: auto;
    left: -$theme-panel-width;
    transition: left 0.2s linear;
  }

  & .theme-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: rem(-4px);
    margin-bottom: rem(-4px);

    & .theme-list-item {
      margin-bottom: rem(4px);

      @if $enable-rtl {
        margin-left: rem(4px);
      } @else {
        margin-right: rem(4px);
      }

      & .theme-list-link {
        display: block;
        position: relative;
        transition: all 0.2s linear;
        border-radius: 30px;
        width: rem(28px);
        height: rem(28px);
        text-decoration: none;

        &:hover,
        &:focus {
          opacity: 0.6;
        }
      }
      &.active {
        & .theme-list-link {
          &:before {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            justify-content: center;
            align-items: center;
            opacity: 0.4;
            content: "\f00c";
            color: $white;
            font-size: rem(14px);
            line-height: 1;
            text-align: center;

            @include fontawesome();
          }
          &.bg-white {
            &:before {
              color: $dark;
            }
          }
        }
      }
    }
  }
  & .theme-collapse-btn {
    position: absolute;
    top: 30%;
    left: rem(-30px);
    margin-top: rem(-15px);
    box-shadow: 0 0 15px 0 rgba($black, 0.15);
    border-radius: $border-radius 0 0 $border-radius;
    background: var(--#{$prefix}component-bg);
    width: rem(30px);
    height: rem(30px);
    color: var(--#{$prefix}component-color);
    font-size: rem(18px);
    line-height: rem(30px);
    text-align: center;

    @if $enable-rtl {
      right: rem(-30px);
      left: auto;
      border-radius: 0 $border-radius $border-radius 0;
    }
    @include media-breakpoint-down(sm) {
      top: 35%;
    }
  }
  & .theme-panel-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    margin: 0;
    background: var(--#{$prefix}component-bg);
    padding: rem(20px);
    overflow: scroll;
  }
  & .theme-panel-divider {
    margin: rem(15px) 0;
    background-color: var(--#{$prefix}component-border-color);
    height: rem(1px);
  }
  & .theme-version {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 rem(5px);
    margin: -2.5px;

    & .theme-version-item {
      position: relative;
      padding-top: rem(35%);
      width: 50%;

      & .theme-version-link {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 2.5px;
        text-decoration: none;

        & .theme-version-cover {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: 0;
          background-position: top left;
          background-size: cover;
          background-repeat: no-repeat;
          font-weight: bold;
          font-size: rem(14px);
          text-align: center;
        }
        &.active,
        &:hover {
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            border: rem(12px) solid transparent;
            border-top-color: lighten($dark, 45%);
            border-left-color: lighten($dark, 45%);
            content: "";
          }
          &:after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: rem(16px);
            height: rem(16px);
            content: "\f00c";
            color: $white;
            font-size: rem(10px);
            line-height: rem(16px);
            text-align: center;

            @include fontawesome();
          }
        }
        &.active {
          &:before {
            border-top-color: #007aff;
            border-left-color: #007aff;
          }
        }
      }
    }
  }
  &.active {
    box-shadow: 0 5px 25px rgba($black, 0.3);

    @if $enable-rtl {
      left: 0;
    } @else {
      right: 0;
    }

    & .theme-collapse-btn {
      & .fa {
        &:before {
          content: "\f00d";
        }
      }
    }
  }
}
