.result-list {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style-type: none;

  & .result-item {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    border-radius: $border-radius-lg;
    background: var(--#{$prefix}component-bg);
    overflow: hidden;

    @include clearfix();
    @include media-breakpoint-down(xl) {
      padding-top: rem(160px);
    }
    @include media-breakpoint-down(md) {
      display: block;
    }

    & + .result-item {
      margin-top: $spacer * 0.5;
    }
    & .result-image {
      background: $dark;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0;
      width: rem(240px);
      overflow: hidden;

      @include media-breakpoint-down(xl) {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: auto;
        height: rem(160px);
      }

      & a {
        display: block;
      }
      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
    & .result-info {
      position: relative;
      flex: 1;
      padding: $spacer * 1.25;

      & .title {
        margin: 0 0 $spacer * 0.25;
        font-size: $h4-font-size;
        line-height: $headings-line-height;

        & a {
          color: var(--#{$prefix}component-color);
          text-decoration: none;
        }
      }
      & .location {
        margin-bottom: $spacer * 0.5;
        color: rgba(var(--#{$prefix}component-color-rgb), 0.5);
      }
      & .desc {
        margin-bottom: $spacer * 2;
      }
      & .btn-row {
        display: flex;
        flex-wrap: wrap;

        & a {
          border-radius: $border-radius;
          background: rgba(var(--#{$prefix}component-color-rgb), 0.15);
          padding: $spacer * 0.333 $spacer * 0.5;
          color: var(--#{$prefix}component-color);
          font-size: $font-size-lg;

          & + a {
            @if $enable-rtl {
              margin-right: $spacer * 0.333;
            } @else {
              margin-left: $spacer * 0.333;
            }
          }
          &:hover,
          &:focus {
            background: rgba(var(--#{$prefix}component-color-rgb), 0.3);
          }
        }
      }
    }
    & .result-price {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--#{$prefix}light);
      padding: $spacer * 1.25;
      width: rem(240px);
      color: var(--#{$prefix}component-color);
      font-size: rem(28px);
      text-align: center;

      @include media-breakpoint-down(lg) {
        width: rem(180px);
      }
      @include media-breakpoint-down(md) {
        padding: $spacer;
        width: auto;
      }

      & small {
        display: block;
        color: rgba(var(--#{$prefix}component-color-rgb), 0.5);
        font-weight: $font-weight-bold;
        font-size: $font-size-sm;
      }
      & .btn {
        margin-top: $spacer * 2;

        @include media-breakpoint-down(xl) {
          margin-top: $spacer;
        }
      }
    }
  }
}
