.chats {
  margin: 0;
  padding: $spacer;
  list-style-type: none;

  & .chats-item {
    @include clearfix();

    & .name {
      display: block;
      margin-bottom: $spacer * 0.333;
      color: var(--#{$prefix}component-color);
      font-weight: $font-weight-bold;
      text-decoration: none;

      & .badge {
        position: relative;
        top: rem(-1px);
        margin-left: rem(3px);
        padding: rem(1px) rem(4px) rem(2px);
        font-size: rem(8px);
      }
    }
    & .date-time {
      display: block;
      margin-top: rem(3px);
      color: rgba(var(--#{$prefix}component-color-rgb), 0.5);
      font-weight: $font-weight-bold;
      font-size: rem(10px);

      @if $enable-rtl {
        float: left;
      } @else {
        float: right;
      }
    }
    & .image {
      float: left;
      border-radius: $border-radius;
      width: rem(60px);
      height: rem(60px);
      overflow: hidden;

      @if $enable-rtl {
        float: right;
      }

      & img {
        max-width: 100%;
      }
      & + .message {
        margin-left: rem(75px);

        @if $enable-rtl {
          margin-right: rem(75px);
          margin-left: 0;
        }
      }
    }
    & .message {
      position: relative;
      border-radius: $border-radius * 3;
      background: var(--#{$prefix}component-bg);
      padding: rem(7px) rem(12px);
      font-size: rem(12px);

      &:before {
        position: absolute;
        top: rem(15px);
        left: rem(-13px);
        border: 7px solid transparent;
        border-right-color: var(--#{$prefix}component-bg);
        content: "";

        @if $enable-rtl {
          right: rem(-13px);
          left: auto;
          border-right-color: transparent;
          border-left-color: var(--#{$prefix}component-bg);
        }
      }
    }
    & + .chats-item {
      margin-top: $spacer;
    }
  }
  & .end {
    & .name {
      @if $enable-rtl {
        text-align: left;
      } @else {
        text-align: right;
      }
    }
    & .date-time {
      @if $enable-rtl {
        float: right;
      } @else {
        float: left;
      }
    }
    & .image {
      @if $enable-rtl {
        float: left;
      } @else {
        float: right;
      }

      & + .message {
        @if $enable-rtl {
          margin-right: 0;
          margin-left: rem(75px);
        } @else {
          margin-right: rem(75px);
          margin-left: 0;
        }
      }
    }
    & .message {
      text-align: right;

      &:before {
        @if $enable-rtl {
          right: auto;
          left: rem(-13px);
          border-right-color: var(--#{$prefix}component-bg);
          border-left-color: transparent;
        } @else {
          right: rem(-13px);
          left: auto;
          border-right-color: transparent;
          border-left-color: var(--#{$prefix}component-bg);
        }
      }
    }
  }
}
.panel-body .chats {
  padding: 0;
}
.slimScrollDiv .chats {
  padding-right: rem(15px);

  @if $enable-rtl {
    padding-right: 0;
    padding-left: rem(15px);
  }
}
