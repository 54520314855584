.nav-wizards-container {
  @include media-breakpoint-down(md) {
    overflow: scroll;

    & .nav.nav-wizards-1,
    & .nav.nav-wizards-2,
    & .nav.nav-wizards-3 {
      min-width: rem(600px);
    }
  }
}

.nav {
  &.nav-wizards-1 {
    flex-wrap: nowrap;

    & .nav-item {
      padding: 0;

      & .nav-link {
        position: relative;
        color: rgba(var(--#{$prefix}component-color-rgb), 0.75);

        & .nav-no {
          display: flex;
          position: relative;
          justify-content: center;
          align-items: center;
          z-index: 10;
          transition: all 0.2s linear;
          margin: 0 auto;
          border: 2px solid var(--#{$prefix}component-border-color);
          border-radius: 40px;
          background: none;
          width: rem(40px);
          height: rem(40px);
          font-weight: $font-weight-bold;
          font-size: $font-size-lg;
        }
        & .nav-text {
          margin-top: $spacer * 0.5;
          font-weight: $font-weight-bold;
          font-size: $font-size-base;
          text-align: center;
        }
        &.active,
        &.completed {
          color: $component-active-bg;

          & .nav-no {
            border-color: $component-active-bg;
            color: $component-active-bg;
          }
          &:before,
          &:after {
            background: $component-active-bg;
          }
          &:after {
            width: calc(50% - 20px);
          }
        }
        &.active {
          & .nav-no {
            box-shadow: 0 0 0 5px rgba($component-active-bg, 0.2);
          }
        }
        &.disabled {
          color: rgba(var(--#{$prefix}component-color-rgb), 0.25);
        }
        &:before,
        &:after {
          position: absolute;
          top: $nav-link-padding-y + rem-default(19px);
          background: var(--#{$prefix}component-border-color);
          height: rem(2px);
          content: "";

          @if $enable-rtl {
            right: 0;
            left: calc(50% + 20px);
          } @else {
            right: calc(50% + 20px);
            left: 0;
          }
        }
        &:after {
          transition: all 0.2s linear;

          @if $enable-rtl {
            right: calc(50% + 20px);
            left: 0;
          } @else {
            right: 0;
            left: calc(50% + 20px);
          }
        }
      }
      &:first-child {
        & .nav-link {
          &:before,
          &:after {
            @if $enable-rtl {
              right: calc(50% + 20px);
            } @else {
              left: calc(50% + 20px);
            }
          }
        }
      }
      &:last-child {
        & .nav-link {
          &:before {
            @if $enable-rtl {
              left: calc(50% + 20px);
            } @else {
              right: calc(50% + 20px);
            }
          }
          &.active,
          &.completed {
            &:before {
              background: $component-active-bg;
            }
          }
          &:after {
            display: none;
          }
        }
      }
    }
  }
  &.nav-wizards-2 {
    flex-wrap: nowrap;

    & .nav-item {
      padding: 0 $nav-link-padding-x;

      & .nav-link {
        position: relative;
        border-radius: 60px;
        background: var(--#{$prefix}component-disabled-bg);
        color: var(--#{$prefix}component-color);

        & .nav-text {
          position: relative;
          z-index: 10;
          background: inherit;
          overflow: hidden;
          font-weight: $font-weight-bold;
          font-size: $font-size-base;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:before,
        &:after {
          position: absolute;
          top: 50%;
          right: $nav-link-padding-x * -1;
          left: $nav-link-padding-x * -1;
          transition: all 0.2s linear;
          margin-top: rem(-1px);
          background-color: var(--#{$prefix}component-disabled-bg);
          height: rem(2px);
          content: "";
        }
        &:after {
          background: $component-active-bg;
          width: 0%;
        }
        &.disabled {
          color: rgba(var(--#{$prefix}component-color-rgb), 0.25);
        }
        &.active,
        &.completed {
          background: $component-active-bg;
          color: $component-active-color;

          &:after {
            width: calc(100% + #{$nav-link-padding-x * 2});
          }
        }
        &.active {
          box-shadow: 0 0 0 4px rgba($component-active-bg, 0.2);
        }
      }
      &:first-child {
        & .nav-link {
          &:before,
          &:after {
            @if $enable-rtl {
              right: 50%;
            } @else {
              left: 50%;
            }
          }
        }
      }
      &:last-child {
        & .nav-link {
          &:before {
            @if $enable-rtl {
              left: 50%;
            } @else {
              right: 50%;
            }
          }
          &.active,
          &.completed {
            &:before {
              background: $component-active-bg;
            }
          }
          &:after {
            display: none;
          }
        }
      }
    }
  }
  &.nav-wizards-3 {
    flex-wrap: nowrap;

    & .nav-item {
      padding: 0;

      & .nav-link {
        position: relative;
        color: rgba(var(--#{$prefix}component-color-rgb), 0.5);

        & .nav-dot {
          display: flex;
          position: relative;
          justify-content: center;
          align-items: center;
          z-index: 10;
          transition: all 0.2s linear;
          margin: 0 auto;
          border: 3px solid var(--#{$prefix}component-disabled-bg);
          border-radius: 10px;
          width: rem(16px);
          height: rem(16px);
          font-weight: 500;
        }
        & .nav-title {
          margin-top: $spacer * 0.5;
          font-weight: $font-weight-bold;
          font-size: $font-size-lg;
          text-align: center;
        }
        & .nav-text {
          color: rgba(var(--#{$prefix}component-color-rgb), 0.65);
          font-weight: $font-weight-bold;
          font-size: $font-size-base;
          text-align: center;
        }
        &.active,
        &.completed {
          color: var(--#{$prefix}component-color);

          & .nav-dot {
            border-color: $component-active-bg;
            color: $component-active-bg;
          }
          &:before,
          &:after {
            background: $component-active-bg;
          }
          &:after {
            width: calc(50% - 8px);
          }
        }
        &.active {
          & .nav-dot {
            box-shadow: 0 0 0 5px rgba($component-active-bg, 0.2);
          }
        }
        &.disabled {
          color: rgba(var(--#{$prefix}component-color-rgb), 0.25);

          & .nav-text {
            color: inherit;
          }
        }
        &:before,
        &:after {
          position: absolute;
          top: $nav-link-padding-y + rem-default(6px);
          background: var(--#{$prefix}component-disabled-bg);
          height: rem(3px);
          content: "";

          @if $enable-rtl {
            right: 0;
            left: calc(50% + 8px);
          } @else {
            right: calc(50% + 8px);
            left: 0;
          }
        }
        &:after {
          transition: all 0.2s linear;

          @if $enable-rtl {
            right: calc(50% + 8px);
            left: 0;
          } @else {
            right: 0;
            left: calc(50% + 8px);
          }
        }
      }
      &:first-child {
        & .nav-link {
          &:before,
          &:after {
            @if $enable-rtl {
              right: calc(50% + 8px);
            } @else {
              left: calc(50% + 8px);
            }
          }
        }
      }
      &:last-child {
        & .nav-link {
          &:before {
            @if $enable-rtl {
              left: calc(50% + 8px);
            } @else {
              right: calc(50% + 8px);
            }
          }
          &.active,
          &.completed {
            &:before {
              background: $component-active-bg;
            }
          }
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
