body {
  & #gritter-notice-wrapper {
    top: $spacer * 1.33;
    right: $spacer * 1.33;
    z-index: $app-header-zindex + 10;
    width: rem(420px);

    @include media-breakpoint-down(sm) {
      top: 0;
      right: 0;
      padding: $app-content-padding-y-sm * 0.75 $app-content-padding-x-sm;
      width: auto;
      max-width: rem(360px);
    }

    & .gritter-item-wrapper {
      box-shadow: $box-shadow-lg;
      border-radius: $border-radius;
      background: none;
      background: rgba(#{var(--#{$prefix}gray-900-rgb)}, 0.95);

      .dark-mode & {
        background: rgba(#{var(--#{$prefix}gray-700-rgb)}, 0.95);
      }

      & .gritter-top,
      & .gritter-bottom {
        display: none;
        background: none;
      }
      & .gritter-item {
        display: flex;
        flex: 1;
        align-items: center;
        background: none;
        padding: $spacer * 0.75 $spacer;
        font-family: inherit;

        @if $enable-rtl {
          padding-left: rem(100px);
        } @else {
          padding-right: rem(100px);
        }
        @include media-breakpoint-down(sm) {
          padding: $spacer;

          @if $enable-rtl {
            padding-left: rem(80px);
          } @else {
            padding-right: rem(80px);
          }
        }

        & .gritter-with-image,
        & .gritter-without-image {
          flex: 1;
          float: none;
          width: auto;
        }
        & .gritter-image {
          margin: 0;
          border-radius: $border-radius * 20;
          height: auto;

          @if $enable-rtl {
            margin-left: $spacer;
          } @else {
            margin-right: $spacer;
          }
          @include media-breakpoint-down(sm) {
            width: rem(36px);
          }
        }
        & .gritter-title {
          display: block;
          margin-bottom: rem(2px);
          padding: 0;
          color: $white;
          font-weight: $font-weight-bold;
          font-size: $h5-font-size;
          line-height: $headings-line-height;
          text-shadow: none;

          @include media-breakpoint-down(sm) {
            overflow: hidden;
            font-size: $font-size-lg;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        & p {
          color: $gray-500;
          font-size: $font-size-sm;
          line-height: $line-height-base;

          @include media-breakpoint-down(sm) {
            max-height: ($font-size-sm * $line-height-base * 2);
            overflow: hidden;
          }
        }
        & .gritter-close {
          display: block !important;
          top: 0;
          right: 0;
          bottom: 0;
          left: auto;
          background: none;
          width: rem(85px);
          height: auto;
          color: $gray-500;
          font-family: inherit;

          @if $enable-rtl {
            right: auto;
            left: 0;
            border-right: 1px solid rgba($white, 0.1);
          } @else {
            border-left: 1px solid rgba($white, 0.1);
          }
          @include media-breakpoint-down(sm) {
            width: rem(65px);
          }

          &:before {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            justify-content: center;
            align-items: center;
            z-index: 10;
            content: "Close";
            color: inherit;
            font-weight: $font-weight-bold;
            font-size: $font-size-base;
            font-family: inherit;
            text-align: center;
            text-indent: 0;
          }
          &:hover,
          &:focus {
            outline: none;
            color: $white;
          }
        }
      }

      &.gritter-light {
        background: rgba($white, 0.98);

        & .gritter-item,
        & .gritter-bottom,
        & .gritter-top {
          color: $gray-600;
        }
        & .gritter-item {
          & .gritter-title {
            color: $gray-900;
          }
          & p {
            color: $gray-700;
          }
        }
        & .gritter-close {
          border-color: $gray-300;
          color: lighten($dark, 30%);

          &:hover,
          &:focus {
            &:before {
              color: $gray-900;
            }
          }
        }
      }
      &.without-rounded-img {
        & .gritter-item {
          & .gritter-image {
            border-radius: 0;
          }
        }
      }
    }
  }
}
