.app-content {
  margin-left: $app-sidebar-width;
  padding: $app-content-padding-y $app-content-padding-x;

  & .app-content-container {
    margin: (-$app-content-padding-y) (-$app-content-padding-x);
    padding: $app-content-padding-y $app-content-padding-x;

    @include media-breakpoint-down(md) {
      margin: (-$app-content-padding-y-sm) (-$app-content-padding-x-sm);
      padding: $app-content-padding-y-sm $app-content-padding-x-sm;
    }
  }
  & .app-content-margin {
    margin: (-$app-content-padding-y) (-$app-content-padding-x);

    @include media-breakpoint-down(md) {
      margin: (-$app-content-padding-y-sm) (-$app-content-padding-x-sm);
    }
  }
  & .app-content-padding {
    padding: $app-content-padding-y $app-content-padding-x;

    @include media-breakpoint-down(md) {
      padding: $app-content-padding-y-sm $app-content-padding-x-sm;
    }
  }

  @if $enable-rtl {
    margin-right: $app-sidebar-width;
    margin-left: 0;
  }
  @include media-breakpoint-down(md) {
    margin-left: 0;
    padding: $app-content-padding-y-sm $app-content-padding-x-sm;

    @if $enable-rtl {
      margin-right: 0;
    }
  }
}
