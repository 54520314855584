.widget-chat {
  background: var(--#{$prefix}component-bg);
  color: var(--#{$prefix}component-color);

  & .widget-chat-header {
    display: flex;
    align-items: center;
    padding: $spacer;

    & .widget-chat-header-icon {
      @if $enable-rtl {
        padding-left: $spacer;
      } @else {
        padding-right: $spacer;
      }
    }
    & .widget-chat-header-content {
      flex: 1;
    }
    & .widget-chat-header-title {
      margin: 0;
      font-size: $h6-font-size;
    }
    & .widget-chat-header-desc {
      margin: 0;
      color: rgba(var(--#{$prefix}component-color-rgb), 0.65);
    }
  }
  & .widget-chat-body {
    background: var(--#{$prefix}light);
    padding: $spacer;
  }
  & .widget-chat-item {
    position: relative;
    padding: $spacer * 0.333 0;
    min-height: rem(38px);
    color: var(--#{$prefix}component-color);
  }
  & .widget-chat-media {
    display: flex;
    position: absolute;
    bottom: rem(3px);
    justify-content: center;
    align-items: center;
    border-radius: $border-radius * 10;
    width: rem(38px);
    height: rem(38px);
    overflow: hidden;

    @if $enable-rtl {
      right: 0;
    } @else {
      left: 0;
    }

    & img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
  & .widget-chat-info {
    display: flex;

    @if $enable-rtl {
      margin-right: rem(12px);
    } @else {
      margin-left: rem(12px);
    }
  }
  & .widget-chat-info-container {
    display: inline-flex;
    position: relative;
    flex-wrap: wrap;
    align-items: flex-end;
    border-radius: $border-radius * 4;
    background: var(--#{$prefix}component-bg);
    padding: $spacer * 0.5 $spacer * 0.75;
    color: var(--#{$prefix}component-color);
  }
  & .widget-chat-message {
    flex: 1;
    color: var(--#{$prefix}component-color);
    font-weight: $font-weight-bold;

    &:before {
      position: absolute;
      bottom: 0;
      border: 0 solid transparent;
      border-top-width: rem(15px);
      border-radius: 50%;
      width: rem(35px);
      height: rem(35px);
      content: "";

      @if $enable-rtl {
        right: rem(-24px);
        transform: rotate(-10deg);
        border-left-width: rem(10px);
        border-left-color: var(--#{$prefix}component-bg);
      } @else {
        left: rem(-24px);
        transform: rotate(10deg);
        border-right-width: rem(10px);
        border-right-color: var(--#{$prefix}component-bg);
      }
    }
  }
  & .widget-chat-name {
    width: 100%;
    font-weight: $font-weight-bold;
  }
  & .widget-chat-time {
    color: $gray-500;
    font-style: italic;
    font-weight: $font-weight-bold;
    font-size: $font-size-base * 0.9;

    @if $enable-rtl {
      padding-right: rem(12px);
    } @else {
      padding-left: rem(12px);
    }
  }
  & .with-media {
    & .widget-chat-info {
      min-width: 60%;

      @if $enable-rtl {
        margin-right: rem(53px);
      } @else {
        margin-left: rem(53px);
      }
    }
    & .end {
      & .widget-chat-info {
        @if $enable-rtl {
          margin-left: rem(53px);
        } @else {
          margin-right: rem(53px);
        }
      }
    }
  }
  & .end {
    & .widget-chat-media {
      @if $enable-rtl {
        right: auto;
        left: 0;
      } @else {
        right: 0;
        left: auto;
      }
    }
    & .widget-chat-info {
      @if $enable-rtl {
        justify-content: flex-start;
        margin-right: 0;
        margin-left: rem(12px);
      } @else {
        justify-content: flex-end;
        margin-right: rem(12px);
        margin-left: 0;
      }
    }
    & .widget-chat-message {
      &:before {
        bottom: rem(-5px);

        @if $enable-rtl {
          right: auto;
          left: rem(-24px);
          border-right-width: rem(10px);
          border-left-width: 0;
          border-right-color: var(--#{$prefix}component-bg);
          border-left-color: transparent;
        } @else {
          right: rem(-24px);
          left: auto;
          border-right-width: 0;
          border-left-width: rem(10px);
          border-right-color: transparent;
          border-left-color: var(--#{$prefix}component-bg);
        }
      }
    }
    &.with-media {
      & .widget-chat-info {
        @if $enable-rtl {
          margin-left: rem(53px);
        } @else {
          margin-right: rem(53px);
        }
      }
    }
  }
}
