@mixin helper-row-space($from, $to) {
  @for $i from $from through $to {
    $classInt: $i * 2;
    $classValue: $classInt * 0.5;

    .row.row-space-#{$classInt} {
      margin-right: rem($classValue * -1);
      margin-left: rem($classValue * -1);
    }
    .row.row-space-#{$classInt} > [class*="col-"] {
      padding-right: rem($classValue);
      padding-left: rem($classValue);
    }
  }
}
