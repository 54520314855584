.legend {
  z-index: 1010;
  border: 1px solid var(--#{$prefix}component-border-color);
  background: rgba(var(--#{$prefix}component-bg-rgb), 0.9);

  & .legendLayer {
    & .background {
      fill: none;
    }
    & text {
      fill: var(--#{$prefix}component-color);
    }
  }
}
.flot-x-axis,
.flot-y-axis {
  fill: var(--#{$prefix}component-color);
}
.flot-tooltip {
  display: none;
  position: absolute;
  z-index: 10000 !important;
  border: 0;
  border-radius: $border-radius;
  background: rgba($dark-darker, 0.85);
  padding: rem(6px) rem(10px);
  color: rgba($white, 0.9);
  font-weight: 600;
  font-size: rem(11px);
}
