.app-with-end-sidebar {
  & .app-sidebar-float-submenu-container {
    & .app-sidebar-float-submenu-line {
      @if $enable-rtl {
        right: auto;
        left: rem(26px);
      } @else {
        right: rem(26px);
        left: auto;
      }
    }
    & .app-sidebar-float-submenu-arrow {
      @if $enable-rtl {
        right: auto;
        left: 0;
      } @else {
        right: 0;
        left: auto;
      }
    }
    & .app-sidebar-float-submenu .menu-item:after {
      @if $enable-rtl {
        right: auto;
        left: 0;
      } @else {
        right: 0;
        left: auto;
      }
    }
    & .app-sidebar-float-submenu {
      @if $enable-rtl {
        padding-right: 0;
        padding-left: rem(39px);
        text-align: left;
      } @else {
        padding-right: rem(39px);
        padding-left: 0;
        text-align: right;
      }

      & .menu-item {
        & > .menu-link {
          flex-direction: row-reverse;

          @if $enable-rtl {
            padding-right: rem(15px);
            padding-left: rem(20px);
          } @else {
            padding-right: rem(20px);
            padding-left: rem(15px);
          }

          & .menu-caret {
            &:before {
              content: "\f053";
            }
          }
          &:after {
            @if $enable-rtl {
              right: auto;
              left: rem(-11px);
            } @else {
              right: rem(-11px);
              left: auto;
            }
          }
        }
        &.active,
        &.expanding,
        &.expand {
          & > .menu-link {
            & .menu-caret {
              &:before {
                transform: rotate(-90deg);
              }
            }
          }
        }
        &:before {
          @if $enable-rtl {
            right: auto;
            left: rem(-13px);
          } @else {
            right: rem(-13px);
            left: auto;
          }
        }
      }
      & .menu-submenu {
        @if $enable-rtl {
          padding-right: 0;
          padding-left: rem(15px);
        } @else {
          padding-right: rem(15px);
          padding-left: 0;
        }
      }
    }
  }
  & .app-sidebar,
  & .app-sidebar-bg {
    right: 0;
    left: auto;

    @if $enable-rtl {
      right: auto;
      left: 0;
    }
    @include media-breakpoint-down(md) {
      right: $app-sidebar-width * -1;

      @if $enable-rtl {
        right: auto;
        left: $app-sidebar-width * -1;
      }
    }
  }
  & .app-content {
    margin-right: $app-sidebar-width;
    margin-left: 0;

    @if $enable-rtl {
      margin-right: 0;
      margin-left: $app-sidebar-width;
    }
    @include media-breakpoint-down(md) {
      margin-right: 0;

      @if $enable-rtl {
        margin-left: 0;
      }
    }
  }

  & .btn-scroll-to-top {
    right: auto;
    left: rem(25px);

    @if $enable-rtl {
      right: rem(25px);
      left: auto;
    }
  }
}
