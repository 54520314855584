// ANIMATION - fade out
@keyframes appLoaderFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.app-loader {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: $app-loader-zindex;
  background-color: var(--#{$prefix}body-bg);
  height: 100vh;

  &.fading {
    opacity: 0;
    animation: appLoaderFadeOut 0.2s ease;
  }
  &.loaded {
    display: none;

    & .spinner {
      animation: none;
    }
  }
}
