.nav {
  &.nav-tabs {
    &.nav-tabs-inverse {
      border-radius: $border-radius $border-radius 0 0;
      background-color: var(--#{$prefix}gray-900);

      & .nav-item {
        & .nav-link {
          color: rgba(var(--#{$prefix}white-rgb), 0.65);

          &:hover,
          &:focus {
            color: rgba(var(--#{$prefix}white-rgb), 1);
          }
          &.active {
            background-color: var(--#{$prefix}component-bg);
            color: var(--#{$prefix}component-color);
          }
        }
      }
    }
    &.nav-tabs-v2 {
      border-bottom: 1px solid var(--#{$prefix}component-border-color);

      & > .nav-item {
        margin-bottom: -1px;

        & > .nav-link {
          border: none;
          border-bottom: 3px solid transparent;
          padding: $nav-link-padding-x $nav-link-padding-x
            $nav-link-padding-x - rem-default(2px);

          &:hover {
            border-bottom-color: rgba(
              var(--#{$prefix}component-color-rgb),
              0.25
            );
          }
          &.active {
            border-bottom-color: var(--#{$prefix}component-active-bg);
            background: none;
          }
        }
        &.show {
          & > .nav-link {
            border-bottom-color: $gray-300;
            background: none;
            color: $gray-900;
          }
        }
      }
    }
  }
}
