@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}
.panel {
  display: flex;
  flex-direction: column;
  margin-bottom: $panel-margin-bottom;
  box-shadow: none;
  border: none;
  border-radius: $border-radius;
  background-color: $panel-bg-color;

  & .panel-body,
  & .panel-toolbar,
  & .panel-footer {
    flex: 1 1 auto;
  }
  & .panel-heading {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border: none;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    padding: $panel-heading-padding-y $panel-heading-padding-x;

    & .panel-title {
      flex: 1;
      margin: 0;
      font-size: $font-size-base;
      line-height: $line-height-base * 0.9;

      & > a {
        display: block;
        color: inherit;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
    & .panel-heading-btn {
      display: flex;
      align-items: center;

      & > a {
        & + a {
          margin-left: rem(5px);

          @if $enable-rtl {
            margin-right: rem(5px);
            margin-left: 0;
          }
        }
      }
      & .btn-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
    & .tab-overflow {
      flex: 1;
    }
  }
  & .panel-body {
    padding: $panel-body-padding-y $panel-body-padding-x;

    @include clearfix();
  }
  & .panel-toolbar {
    padding: $panel-toolbar-padding-y $panel-toolbar-padding-x;
  }
  & .panel-footer {
    border-top: 1px solid
      rgba(var(--#{$prefix}component-border-color-rgb), 0.75);
    padding: $panel-footer-padding-y $panel-footer-padding-x;
  }
  & [class^="col-"] {
    &.ui-sortable {
      min-height: 0;
    }
  }
  &.panel-default {
    & > .panel-heading {
      background: var(--#{$prefix}light);
      color: var(--#{$prefix}component-color);
    }
  }
  &.panel-inverse {
    & > .panel-heading {
      background: var(--#{$prefix}gray-900);
      color: var(--#{$prefix}white);

      .dark-mode & {
        background: rgba($white, 0.1);
      }
    }
  }
  &.panel-loading {
    & .panel-body {
      position: relative;
      z-index: 0;
      overflow: hidden;

      & .panel-loader {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        opacity: 0.9;
        z-index: $app-header-zindex + 10;
        animation: fadeIn 0.2s;
        border-radius: 0 0 $border-radius $border-radius;
        background: var(--#{$prefix}component-bg);
      }
    }
  }
  &.panel-expand {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex: 1;
    flex-direction: column;
    z-index: $panel-expand-zindex;
    margin: 0;
    border-radius: 0;
    overflow: hidden;

    & > .panel-body,
    & > .panel-heading,
    & > .panel-footer {
      border-radius: 0;
    }
    & > .panel-body {
      flex: 1;
      overflow-x: scroll;

      & > .slimScrollDiv {
        height: 100% !important;

        & > div:not(.slimScrollBar):not(.slimScrollRail) {
          height: 100% !important;
        }
      }
    }
    & > .panel-heading {
      flex: 0;

      & .fa-expand:before {
        content: "\f066";
      }
    }
    & > .hljs-wrapper {
      max-height: 50%;
      overflow: scroll;
    }
  }
  &.panel-hover-icon {
    & .panel-heading {
      & .panel-heading-btn {
        & .btn {
          & i {
            visibility: hidden;
          }
        }
      }

      &:hover,
      &:focus {
        & .panel-heading-btn {
          & .btn {
            & i {
              visibility: visible;
            }
          }
        }
      }
    }
  }
  &.panel-with-tabs {
    & .panel-heading {
      & > .nav-tabs {
        margin-top: -$panel-heading-padding-y * 0.5;
        margin-bottom: -$panel-heading-padding-y;
        border-bottom: none;

        & .nav-item {
          & .nav-link {
            margin: 0;
            border: none;
          }
        }
      }
    }
  }
}
.panel-expand {
  & .app-content {
    z-index: 9999;

    & .panel.panel-expand {
      background: $panel-bg-color;
    }
  }
}
