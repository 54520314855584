.page-header {
  margin: 0 0 rem(15px);
  border: none;
  padding: 0;
  font-weight: $page-header-font-weight;
  font-size: $page-header-font-size;
  line-height: $page-header-line-height;

  & small {
    line-height: rem(20px);
  }
}
