.invoice {
  background: var(--#{$prefix}component-bg);
  padding: rem(20px);

  & > div {
    &:not(.invoice-footer) {
      margin-bottom: rem(20px);
    }
  }
  & .invoice-company {
    font-weight: $font-weight-bold;
    font-size: rem(20px);
  }
  & .invoice-header {
    display: flex;
    margin: 0 rem(-20px);
    background: var(--#{$prefix}light);

    @include media-breakpoint-down(md) {
      display: block;

      & > div {
        & + div {
          border-top: 1px solid mix($black, $light, 5%);
        }
      }
    }
  }
  & .invoice-from {
    flex: 1;
    padding: rem(20px);

    & strong {
      font-weight: $font-weight-bold;
      font-size: rem(16px);
    }
  }
  & .invoice-to {
    flex: 1;
    padding: rem(20px);

    & strong {
      font-weight: $font-weight-bold;
      font-size: rem(16px);
    }
  }
  & .invoice-date {
    flex: 1;
    padding: rem(20px);
    text-align: right;

    @include media-breakpoint-down(md) {
      text-align: left;
    }

    & .date {
      font-weight: $font-weight-bold;
      font-size: rem(16px);
    }
  }
  & .invoice-price {
    display: flex;
    background: var(--#{$prefix}light);
    width: 100%;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    & small {
      display: block;
      font-weight: normal;
      font-size: rem(12px);
    }
    & .invoice-price-right {
      display: flex;
      position: relative;
      justify-content: flex-end;
      align-items: center;
      vertical-align: bottom;
      margin-left: auto;
      background: var(--#{$prefix}dark);
      padding: rem(20px);
      min-width: 25%;
      color: var(--#{$prefix}light);
      font-weight: 300;
      font-size: rem(28px);
      text-align: right;

      & small {
        display: block;
        position: absolute;
        top: rem(15px);
        left: rem(20px);
        opacity: 0.6;
        font-size: rem(12px);
      }
    }
    & .invoice-price-left {
      position: relative;
      flex: 1;
      vertical-align: middle;
      padding: rem(20px);
      font-weight: $font-weight-bold;
      font-size: rem(20px);

      & .invoice-price-row {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
          display: block;
          text-align: center;
        }

        & .sub-price {
          padding: 0 rem(20px);

          @include media-breakpoint-down(md) {
            padding: 0;

            & + .sub-price {
              padding-left: rem(20px);
            }
          }
          @include media-breakpoint-down(sm) {
            & + .sub-price {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  & .invoice-footer {
    border-top: 1px solid var(--#{$prefix}component-border-color);
    padding-top: rem(15px);
    color: rgba(var(--#{$prefix}component-color-rgb), 0.5);
    font-size: rem(11px);
  }
  & .invoice-note {
    margin-top: rem(80px);
    color: rgba(var(--#{$prefix}component-color-rgb), 0.5);
    font-size: rem(11px);
    line-height: 1.75;
  }
  & .table-invoice {
    font-size: rem(13px);

    @include media-breakpoint-down(md) {
      white-space: nowrap;
    }
  }
}
