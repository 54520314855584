.profile-header {
  position: relative;
  overflow: hidden;

  & .profile-header-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: $app-profile-bg-cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        to bottom,
        rgba($gray-900, 0.25) 0%,
        rgba($gray-900, 1) 100%
      );
      content: "";
    }
  }
  & .profile-header-content {
    position: relative;
    padding: $app-content-padding-x;
    color: $white;

    @include media-breakpoint-down(md) {
      display: flex;
      align-items: center;
      padding: $app-content-padding-x-sm;
    }
  }
  & .profile-header-img {
    position: relative;
    float: left;
    z-index: 10;
    margin: rem(-10px) 0 rem(-20px) 0;
    border-radius: $border-radius;
    background: var(--#{$prefix}component-bg);
    padding: rem(3px);
    width: rem(120px);
    height: rem(120px);
    overflow: hidden;

    @if $enable-rtl {
      float: right;
    }
    @include media-breakpoint-down(md) {
      margin: 0;
      width: rem(80px);
      height: rem(80px);
    }

    & + .profile-header-info {
      margin-left: (rem(120px) + $app-content-padding-x) - rem(10px);

      @if $enable-rtl {
        margin-right: (rem(120px) + $app-content-padding-x) - rem(10px);
        margin-left: 0;
      }
      @include media-breakpoint-down(md) {
        margin-left: rem(20px);

        @if $enable-rtl {
          margin-right: rem(20px);
          margin-left: 0;
        }
      }
    }
    & img {
      max-width: 100%;
    }
  }
  & .profile-header-info {
    & h4 {
      color: $white;
      font-weight: 600;
    }
  }
  & .profile-header-tab {
    position: relative;
    margin: rem(-10px) 0 0;
    border-radius: 0;
    background: var(--#{$prefix}component-bg);
    padding: 0 0 0 (rem(120px) + $app-content-padding-x);
    list-style-type: none;

    @if $enable-rtl {
      padding-right: rem(120px) + $app-content-padding-x;
      padding-left: 0;
    }
    @include media-breakpoint-down(md) {
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
      padding: 0;
      overflow: scroll;

      @if $enable-rtl {
        padding: 0;
      }
    }

    & .nav-item {
      display: inline-block;
      margin: 0;

      & .nav-link {
        display: block;
        border: none;
        border-radius: 0;
        padding: rem(10px) rem(20px);
        color: rgba(var(--#{$prefix}component-color-rgb), 0.5);
        font-weight: bold;
        font-size: rem(12px);
        line-height: rem(20px);
        text-decoration: none;

        &:hover,
        &:focus {
          background: none;
          color: rgba(var(--#{$prefix}component-color-rgb), 0.75);
        }
        &.active {
          background: none;
          color: var(--#{$prefix}component-color);
        }
      }
    }
  }
}
.profile-content {
  padding: rem(25px);

  @include clearfix();

  & .tab-content,
  & .tab-pane {
    background: none;
  }
  & .table {
    &.table-profile {
      & > thead {
        & > tr {
          & > th {
            border: none;
            border-bottom: none !important;
            background: none;
            color: var(--#{$prefix}component-color);

            & h4 {
              margin-top: 0;
              font-size: rem(20px);

              & small {
                display: block;
                margin-top: rem(5px);
                font-weight: normal;
                font-size: rem(12px);
              }
            }
          }
        }
      }
      & tbody {
        & > tr {
          & td {
            border: none;
            background: none;
            color: var(--#{$prefix}component-color);

            &.field {
              width: 20%;
              color: var(--#{$prefix}component-color);
              font-weight: $font-weight-bold;

              @if $enable-rtl {
                text-align: left;
              } @else {
                text-align: right;
              }
              @include media-breakpoint-down(md) {
                width: 35%;
              }
            }
          }
          &.highlight {
            & > td {
              border-top: 1px solid var(--#{$prefix}component-border-color);
              border-bottom: 1px solid var(--#{$prefix}component-border-color);
            }
          }
          &.divider {
            & > td {
              padding: 0 !important;
              height: rem(10px);
            }
          }
        }
      }
    }
  }
}
