.dropdown-menu {
  box-shadow: $dropdown-box-shadow;

  @if $enable-rtl {
    text-align: right;
  }

  & .dropdown-item {
    font-weight: $dropdown-font-weight;
  }

  &.media-list {
    padding: 0;
    max-width: rem(360px);

    & .media {
      display: flex;
      border-top: 1px solid rgba(var(--#{$prefix}component-color-rgb), 0.1);
      border-bottom: 1px solid rgba(var(--#{$prefix}component-color-rgb), 0.1);

      & .media-object {
        display: block;
        border-radius: $border-radius-lg;
        width: rem(48px);
        height: rem(48px);
        color: $white;
        font-size: rem(24px);
        line-height: rem(48px);
        text-align: center;
      }
      & .media-object-icon {
        display: block;
        position: absolute;
        margin-top: rem(-11px);
        border: rem(3px) solid var(--#{$prefix}component-dropdown-bg);
        border-radius: $border-radius * 10;
        background: var(--#{$prefix}component-dropdown-bg);
        width: rem(21px);
        height: rem(21px);
        font-size: rem(16px);
        line-height: rem(16px);
        text-align: center;
        text-align: center;

        @if $enable-rtl {
          margin-right: rem(30px);
        } @else {
          margin-left: rem(30px);
        }
      }
      & .media-left {
        @if $enable-rtl {
          padding-left: rem(12px);
        } @else {
          padding-right: rem(12px);
        }
      }
      & .media-right {
        @if $enable-rtl {
          padding-right: rem(12px);
          padding-left: 0;
        } @else {
          padding-left: rem(12px);
        }
      }
      & .media-heading {
        margin-bottom: 0;
        color: var(--#{$prefix}component-color);
        font-size: rem(12.5px);
        line-height: rem(18px);
      }
      & p {
        margin-bottom: rem(5px);
        max-width: rem(270px);
        overflow: hidden;
        color: rgba(var(--#{$prefix}component-color-rgb), 0.65);
        font-size: rem(11px);
        line-height: rem(14px);
        text-overflow: ellipsis;
      }
      & + .media {
        border-top: none;
      }
    }
    & .dropdown-header {
      background: $dropdown-header-bg;
      padding: $spacer * 0.66 $spacer;
      color: $dropdown-header-color;
      font-weight: $font-weight-bolder;
      font-size: $dropdown-header-font-size;
    }
    & .dropdown-item {
      padding: $spacer * 0.66 $spacer;
    }
    & .dropdown-footer {
      padding: $spacer * 0.66 $spacer;
    }
  }
}
