.messenger {
  --#{$prefix}messenger-menu-width: #{rem(60px)};
  --#{$prefix}messenger-chat-width: #{rem(360px)};

  display: flex;
  position: relative;
  height: 100%;
  overflow: hidden;

  & .messenger-menu {
    display: flex;
    flex-direction: column;
    background: var(--#{$prefix}component-bg);
    padding: $spacer * 0.5;
    width: var(--#{$prefix}messenger-menu-width);
    min-width: var(--#{$prefix}messenger-menu-width);

    @include media-breakpoint-up(lg) {
      @if $enable-rtl {
        border-left: 1px solid var(--#{$prefix}component-border-color);
      } @else {
        border-right: 1px solid var(--#{$prefix}component-border-color);
      }
    }

    @include media-breakpoint-down(lg) {
      display: none;
    }

    & .messenger-menu-item {
      margin-bottom: $spacer * 0.25;

      & .messenger-menu-link {
        display: block;
        border-radius: $border-radius;
        padding: $spacer * 0.5;
        color: var(--#{$prefix}component-color);
        text-decoration: none;

        &:hover,
        &:focus {
          opacity: 0.5;
        }
      }
      &.active {
        & .messenger-menu-link {
          color: var(--#{$prefix}app-theme);
        }
      }
    }
  }
  & .messenger-chat {
    display: flex;
    flex-direction: column;
    background: var(--#{$prefix}component-bg);

    @include media-breakpoint-down(lg) {
      flex: 1;
    }
    @include media-breakpoint-up(lg) {
      width: var(--#{$prefix}messenger-chat-width);

      @if $enable-rtl {
        border-left: 1px solid var(--#{$prefix}component-border-color);
      } @else {
        border-right: 1px solid var(--#{$prefix}component-border-color);
      }
    }

    & .messenger-chat-header {
      border-bottom: 1px solid var(--#{$prefix}component-border-color);
      padding: $spacer;
    }
    & .messenger-chat-body {
      flex: 1;
      overflow: hidden;
    }
    & .messenger-chat-list {
      & .messenger-chat-item {
        &.active {
          & .messenger-chat-link {
            background: var(--#{$prefix}light);
          }
        }
        & .messenger-chat-link {
          display: flex;
          align-items: center;
          padding: $spacer;
          color: var(--#{$prefix}component-color);
          text-decoration: none;

          &:hover,
          &:focus {
            background: var(--#{$prefix}light);
          }
          & .messenger-chat-media {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: $border-radius;
            background: rgba(var(--#{$prefix}component-color-rgb), 0.15);
            width: rem(44px);
            min-width: rem(44px);
            height: rem(44px);
            color: rgba(var(--#{$prefix}component-color-rgb), 0.5);
            font-size: rem(28px);

            & img {
              border-radius: $border-radius;
              max-width: 100%;
              max-height: 100%;
            }
          }
          & .messenger-chat-content {
            flex: 1;
            max-width: calc(100% - #{rem(44px)});

            @if $enable-rtl {
              padding-right: $spacer * 0.75;
            } @else {
              padding-left: $spacer * 0.75;
            }
          }
          & .messenger-chat-title {
            display: flex;
            color: var(--#{$prefix}component-color);
            font-weight: $font-weight-bold;
            font-size: $h5-font-size * 0.9;

            & > div:not(.messenger-chat-time) {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          & .messenger-chat-time {
            color: rgba(var(--#{$prefix}component-color-rgb), 0.35);
            font-size: $font-size-base * 0.9;

            @if $enable-rtl {
              padding-right: $spacer * 0.5;
            } @else {
              padding-left: $spacer * 0.5;
            }
          }
          & .messenger-chat-desc {
            overflow: hidden;
            color: rgba(var(--#{$prefix}component-color-rgb), 0.35);
            font-weight: $font-weight-bold;
            font-size: $font-size-base;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
  & .messenger-content {
    display: none;
    flex: 1;

    @include media-breakpoint-up(lg) {
      display: block;
    }
    @include media-breakpoint-down(md) {
      flex-wrap: nowrap;
      overflow: scroll;
    }

    & .widget-chat {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;

      & .widget-chat-item {
        & .widget-chat-info {
          & .widget-chat-info-container {
            @include media-breakpoint-up(lg) {
              max-width: 51%;
            }
          }
        }
        &.end {
          & .widget-chat-info {
            & .widget-chat-info-container {
              background: var(--#{$prefix}app-theme);

              @if $enable-rtl {
                margin-right: auto;
              } @else {
                margin-left: auto;
              }

              & .widget-chat-message {
                color: var(--#{$prefix}app-theme-color);

                &:before {
                  @if $enable-rtl {
                    border-right-color: var(--#{$prefix}app-theme);
                  } @else {
                    border-left-color: var(--#{$prefix}app-theme);
                  }
                }
              }
              & .widget-chat-time {
                color: rgba(var(--#{$prefix}app-theme-color-rgb), 0.5);
              }
            }
          }
        }
      }
      & .widget-chat-header {
        border-bottom: 1px solid var(--#{$prefix}component-border-color);
      }
      & .widget-chat-body {
        flex: 1;
        @include media-breakpoint-up(lg) {
          overflow: hidden;
        }
      }
      & .widget-chat-input {
        border-top: 1px solid var(--#{$prefix}component-border-color);

        & .widget-chat-toolbar {
          display: flex;
          flex-wrap: wrap;
          padding: $spacer $spacer $spacer * 0.5;

          & .widget-chat-toolbar-link {
            color: rgba(var(--#{$prefix}component-color-rgb), 0.5);
            text-decoration: none;

            &:hover,
            &:focus {
              color: rgba(var(--#{$prefix}component-color-rgb), 1);
            }

            & + .widget-chat-toolbar-link {
              @if $enable-rtl {
                margin-right: $spacer;
              } @else {
                margin-left: $spacer;
              }
            }
          }
        }
        & .form-control {
          box-shadow: none !important;
          border: none !important;
          border-radius: 0 !important;
          background: none !important;
          padding: $spacer * 0.5 $spacer $spacer;
          min-height: rem(100px);
        }
      }
    }
  }
  &.messenger-chat-content-mobile-toggled {
    & .messenger-content {
      @include media-breakpoint-down(lg) {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
      }
    }
  }
}
