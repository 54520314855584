.hljs-wrapper {
  --#{$prefix}hljs-color: #{rgba($component-color, 0.5)};
  --#{$prefix}hljs-tag-color: #{darken($blue, 15%)};
  --#{$prefix}hljs-attr-color: #{lighten($blue, 5%)};
  --#{$prefix}hljs-string-color: #{darken($red, 7.5%)};
  --#{$prefix}hljs-comment-color: #{lighten($dark, 40%)};
  position: relative;
  border-radius: $border-radius;

  background: var(--#{$prefix}light);

  & pre {
    margin: 0;
    border: none;
    border-radius: 0;
    background: none;
    padding: 0;

    & .hljs,
    &.hljs {
      display: block;
      border: none;
      border-radius: 0;
      background: none;
      padding: rem(15px);
      overflow-x: auto;
      color: var(--#{$prefix}hljs-color);
      font-size: rem(12px);

      & .hljs-tag,
      & .hljs-section,
      & .hljs-name,
      & .hljs-selector-tag,
      & .hljs-deletion,
      & .hljs-subst {
        color: var(--#{$prefix}hljs-tag-color);
      }
      & .hljs-attr,
      & .hljs-variable,
      & .hljs-template-variable,
      & .hljs-type,
      & .hljs-selector-class,
      & .hljs-selector-attr,
      & .hljs-selector-pseudo,
      & .hljs-number {
        color: var(--#{$prefix}hljs-attr-color);
      }
      & .hljs-string,
      & .hljs-regexp,
      & .hljs-addition,
      & .hljs-attribute,
      & .hljs-meta-string {
        color: var(--#{$prefix}hljs-string-color);
      }
      & .hljs-comment {
        color: var(--#{$prefix}hljs-comment-color);
      }
    }
  }
  & .btn-clipboard {
    position: absolute;
    top: rem(10px);
    right: rem(10px);

    @if $enable-rtl {
      right: auto;
      left: rem(10px);
    }
  }
}

@if $enable-dark-mode {
  @include color-mode(dark, false) {
    .hljs-wrapper {
      --#{$prefix}hljs-color: #{rgba($component-color-dark, 0.5)};
      --#{$prefix}hljs-tag-color: #{rgba(lighten($blue, 25%), 0.5)};
      --#{$prefix}hljs-attr-color: #{lighten($blue, 15%)};
      --#{$prefix}hljs-string-color: #{tint-color($teal, 25%)};
    }
  }
}
.panel-body + .hljs-wrapper {
  border-radius: 0 0 $border-radius $border-radius;
}
.modal {
  & .hljs-wrapper {
    margin-top: rem(-1px);
    border-radius: 0;

    & + .modal-footer {
      border-top: none;
    }
  }
}
