.pos {
  --#{$prefix}pos-sidebar-bg: #{$pos-sidebar-bg};
  --#{$prefix}pos-sidebar-header-bg: #{$pos-sidebar-header-bg};
  --#{$prefix}pos-sidebar-header-color: #{$pos-sidebar-header-color};
  --#{$prefix}pos-sidebar-header-back-btn-bg: #{$pos-sidebar-header-back-btn-bg};
  --#{$prefix}pos-sidebar-header-nav-link-bg: #{$pos-sidebar-header-nav-link-bg};
  --#{$prefix}pos-sidebar-header-nav-link-color: #{$pos-sidebar-header-nav-link-color};
  --#{$prefix}pos-sidebar-header-nav-link-active-bg: #{$pos-sidebar-header-nav-link-active-bg};
  --#{$prefix}pos-sidebar-header-nav-link-active-color: #{$pos-sidebar-header-nav-link-active-color};
  --#{$prefix}pos-sidebar-footer-bg: #{$pos-sidebar-footer-bg};
  --#{$prefix}pos-sidebar-footer-color: #{$pos-sidebar-footer-color};
  --#{$prefix}pos-sidebar-product-border-color: #{$pos-sidebar-product-border-color};
  --#{$prefix}pos-sidebar-product-confirmation-bg: #{$pos-sidebar-product-confirmation-bg};

  &.pos-with-menu {
    @include media-breakpoint-up(lg) {
      @if ($enable-rtl) {
        padding-right: $pos-menu-width;
      } @else {
        padding-left: $pos-menu-width;
      }
    }
    @include media-breakpoint-down(lg) {
      padding-top: rem(100px);
    }
  }
  &.pos-with-sidebar {
    @include media-breakpoint-up(lg) {
      @if ($enable-rtl) {
        padding-left: $pos-sidebar-width;
      } @else {
        padding-right: $pos-sidebar-width;
      }
    }
  }
  &.pos-with-header {
    padding-top: $pos-header-height;

    &.pos-with-sidebar {
      @include media-breakpoint-up(lg) {
        @if ($enable-rtl) {
          padding-left: $pos-sidebar-width - $spacer * 2;
        } @else {
          padding-right: $pos-sidebar-width - $spacer * 2;
        }
      }

      & .pos-sidebar {
        @include media-breakpoint-up(lg) {
          top: $pos-header-height;
          margin: 0;
          border-top: none;
          border-bottom: none;
          border-radius: 0;
          height: calc(100% - #{$pos-header-height});

          @if ($enable-rtl) {
            border-left: none;
          } @else {
            border-right: none;
          }
        }

        & .pos-sidebar-header,
        & .pos-sidebar-footer {
          border-radius: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    height: 100%;
  }

  & .pos-menu {
    position: fixed;
    top: 0;
    z-index: 10;

    @include media-breakpoint-up(lg) {
      display: flex;
      bottom: 0;
      flex-direction: column;
      width: $pos-menu-width;

      @if ($enable-rtl) {
        right: 0;
        padding-right: $spacer;
      } @else {
        left: 0;
        padding-left: $spacer;
      }
    }
    @include media-breakpoint-down(lg) {
      right: 0;
      bottom: auto;
      left: 0;
      border-bottom: 1px solid var(--#{$prefix}border-color);
      background: var(--#{$prefix}pos-sidebar-bg);
    }

    & .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: $spacer;

      @include media-breakpoint-down(lg) {
        display: flex;
        padding: $spacer * 0.5 $spacer;
      }

      & a {
        display: block;
        color: inherit;
        text-decoration: none;

        @include media-breakpoint-down(lg) {
          display: flex;
        }
      }
      & .logo-img {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(45px);
        font-size: $font-size-base * 3;
        text-align: center;

        @include media-breakpoint-down(lg) {
          height: rem(30px);
          font-size: $font-size-base * 1.5;

          @if ($enable-rtl) {
            margin-left: $spacer * 0.5;
          } @else {
            margin-right: $spacer * 0.5;
          }
        }
      }
      & .logo-text {
        margin-top: $spacer * 0.25;
        font-weight: $font-weight-bold;
        font-size: $font-size-lg;
        letter-spacing: 1px;
      }
      & img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    & .nav-container {
      flex: 1;

      @include media-breakpoint-up(lg) {
        padding: 0;
        overflow: hidden;
      }
    }
    & .nav.nav-tabs {
      display: block;
      margin: 0;
      border: 0;
      padding: 0;

      @include media-breakpoint-down(lg) {
        display: flex;
        flex-wrap: nowrap;
        border-top: 1px solid var(--#{$prefix}border-color);
        padding: 0 $spacer;
        overflow: scroll;
      }

      & .nav-item {
        padding: $spacer * 0.35 $spacer;

        @include media-breakpoint-down(lg) {
          padding: $spacer * 0.5 0;

          & + .nav-item {
            @if ($enable-rtl) {
              margin-right: $spacer * 0.5;
            } @else {
              margin-left: $spacer * 0.5;
            }
          }
        }
        & .nav-link {
          position: relative;
          border-radius: $border-radius-lg;
          background: var(--#{$prefix}component-bg);
          padding: $spacer * 0.75;
          color: var(--#{$prefix}component-color);
          font-weight: $font-weight-semibold;
          font-size: $font-size-base;
          text-align: center;
          white-space: nowrap;

          @include media-breakpoint-down(lg) {
            display: flex;
            align-items: center;
            border-radius: $border-radius;
            padding: $spacer * 0.5 $spacer;
          }

          & .nav-icon {
            display: block;
            margin: 0 auto;
            color: rgba(var(--#{$prefix}component-color-rgb), 0.25);
            font-size: rem(24px);

            @include media-breakpoint-down(lg) {
              margin: 0;
              font-size: $font-size-base * 1.2;

              @if ($enable-rtl) {
                margin-left: $spacer * 0.5;
              } @else {
                margin-right: $spacer * 0.5;
              }
            }
          }
          & .nav-text {
            font-weight: $font-weight-bold;
          }
          &:hover {
            & .nav-icon {
              color: rgba(var(--#{$prefix}component-color-rgb), 0.75);
            }
          }
          &.active {
            background: var(--#{$prefix}component-color);
            color: var(--#{$prefix}component-bg);

            & .nav-icon {
              color: inherit;
            }
          }
        }
      }
    }
  }
  & .pos-content {
    flex: 1;

    & .pos-content-container {
      padding: $spacer;
    }
  }
  & .product-row {
    display: flex;
    flex-wrap: wrap;
    margin: $spacer * -0.75;
    padding: 0;

    & .product-container {
      padding: $spacer * 0.75;
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: 50%;
      }
      @include media-breakpoint-up(md) {
        width: 33.33%;
      }
      @include media-breakpoint-up(lg) {
        width: 50%;
      }
      @include media-breakpoint-up(xl) {
        width: 33.33%;
      }
      @include media-breakpoint-up(xxxl) {
        width: 25%;
      }

      & .product {
        height: 100%;
      }
    }
  }
  & .product {
    display: block;
    display: flex;
    position: relative;
    flex-direction: column;
    transition: all 0.2s linear;
    margin: 0;
    border-radius: $border-radius-lg;
    background: var(--#{$prefix}component-bg);
    overflow: hidden;
    color: var(--#{$prefix}component-color);
    text-decoration: none;

    &:hover {
      box-shadow: $box-shadow;
    }

    & .img {
      transition: all 0.2s linear;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      min-height: rem(180px);
    }
    & .text {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: $spacer * 0.75 $spacer;

      & .title {
        margin: 0 0 rem(2px);
        color: var(--#{$prefix}component-color);
        font-weight: $font-weight-bold;
        font-size: $font-size-lg;
      }
      & .desc {
        flex: 1;
        margin: 0 0 $spacer;
        color: $gray-500;
        font-weight: $font-weight-bold;
        line-height: 1.4;
      }
      & .price {
        margin: 0;
        color: var(--#{$prefix}component-color);
        font-weight: $font-weight-bold;
        font-size: $font-size-lg;
      }
    }
    &.not-available {
      cursor: not-allowed;
      box-shadow: none !important;

      & .img,
      & .text {
        opacity: 0.65;
      }
      & .not-available-text {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        background: rgba($gray-700, 0.75);
        min-height: rem(180px);
        color: $white;
        font-size: $h2-font-size;
      }
    }
  }
  & .pos-task-row {
    & .pos-task {
      border-bottom: 1px solid var(--#{$prefix}component-border-color);

      @include media-breakpoint-up(md) {
        display: flex;
      }
      & .pos-task-info {
        padding: rem(25px);
        width: 30%;

        @if $enable-rtl {
          text-align: left;
        } @else {
          text-align: right;
        }
        @include media-breakpoint-down(md) {
          position: relative;
          width: auto;

          @if $enable-rtl {
            text-align: right;
          } @else {
            text-align: left;
          }
        }

        & .table-no {
          font-size: rem(24px);
        }
        & .order-type {
          font-size: rem(14px);
        }
        & .order-no {
          margin-bottom: rem(5px);
        }
        & .time-pass {
          margin-top: rem(25px);
          font-weight: $font-weight-bold;
          font-size: rem(16px);

          @include media-breakpoint-down(md) {
            position: absolute;
            top: 0;
            margin: 0;
            padding: rem(25px);

            @if $enable-rtl {
              left: 0;
            } @else {
              right: 0;
            }
          }
        }
      }
      & .pos-task-body {
        flex: 1;
        padding: rem(25px);

        @if $enable-rtl {
          border-right: 1px solid var(--#{$prefix}component-border-color);
        } @else {
          border-left: 1px solid var(--#{$prefix}component-border-color);
        }
        @include media-breakpoint-down(md) {
          border-left: none;
          padding-top: 0;
        }

        & .pos-task-completed {
          font-size: rem(16px);
          line-height: rem(36px);

          @include media-breakpoint-down(md) {
            margin-top: rem(-10px);
            border-top: 1px solid var(--#{$prefix}component-border-color);
            padding-top: rem(10px);
          }
        }
        & .pos-task-product-row {
          display: flex;
          flex-wrap: wrap;
          margin: 0 rem(-10px) rem(-20px);

          & .pos-task-product {
            display: flex;
            flex: 0 0 25%;
            flex-direction: column;
            margin-bottom: rem(20px);
            padding-right: rem(10px);
            padding-left: rem(10px);
            width: 25%;

            @include media-breakpoint-down(lg) {
              flex: 0 0 33.33%;
              width: 33.33%;
            }
            @include media-breakpoint-down(sm) {
              flex: 0 0 100%;
              width: 100%;
            }

            & .pos-task-product-img {
              position: relative;
              margin-bottom: rem(10px);
              padding-top: 75%;

              & .cover {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: $border-radius-lg;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
              }
              & .caption {
                display: flex;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                justify-content: center;
                align-items: center;
                border-radius: $border-radius-lg;
                background: rgba($gray-600, 0.75);
                color: $white;
                font-size: rem(24px);
              }
            }
            & .pos-task-product-info {
              display: flex;
              flex: 1;

              & .info {
                flex: 1;

                & .title {
                  font-weight: $font-weight-bold;
                  font-size: rem(16px);

                  & + .desc {
                    padding-top: rem(3px);
                  }
                }
                & .desc {
                  color: rgba(var(--#{$prefix}component-color-rgb), 0.5);
                  font-weight: $font-weight-bold;
                  font-size: $font-size-base;
                }
              }
              & .qty {
                font-weight: $font-weight-bold;
                font-size: rem(18px);
              }
            }
            & .pos-task-product-action {
              padding-top: rem(10px);

              & .btn {
                display: block;
                font-weight: $font-weight-bold;
                font-size: rem(14px);

                & + .btn {
                  margin-top: rem(10px);
                }
              }
            }
            &.completed {
              & .pos-task-product-info {
                opacity: 0.75;
              }
            }
          }
        }
      }
    }
  }
  & .pos-sidebar {
    display: flex;
    flex: 0 0 $pos-sidebar-width;
    flex-direction: column;
    background: var(--#{$prefix}pos-sidebar-bg);
    width: calc(#{$pos-sidebar-width} - #{$spacer * 2});
    height: 100%;
    color: var(--#{$prefix}body-color);

    @include media-breakpoint-up(lg) {
      position: fixed;
      top: 0;
      bottom: 0;
      margin: $spacer;
      border-radius: $border-radius-lg;
      height: calc(100% - #{$spacer * 2});

      @if ($enable-rtl) {
        left: 0;
      } @else {
        right: 0;
      }
    }
    @include media-breakpoint-down(lg) {
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $app-header-zindex + 1;
      width: auto;
    }

    & .pos-sidebar-header {
      display: flex;
      position: relative;
      align-items: center;
      background: var(--#{$prefix}pos-sidebar-header-bg);
      padding: $spacer * 0.75 $spacer;
      color: var(--#{$prefix}pos-sidebar-header-color);

      @include media-breakpoint-up(lg) {
        border-radius: $border-radius-lg $border-radius-lg 0 0;
      }

      & .back-btn {
        display: none;
        margin-top: $spacer * -0.75;
        margin-bottom: $spacer * -0.75;
        border: none;
        background: var(--#{$prefix}pos-sidebar-header-back-btn-bg);
        width: rem(44px);
        height: rem(44px);

        @if $enable-rtl {
          margin-right: $spacer * -1;
          margin-left: $spacer;
        } @else {
          margin-right: $spacer;
          margin-left: $spacer * -1;
        }
        @include media-breakpoint-down(lg) {
          display: block;
        }

        & .btn {
          padding: 0;
          width: 100%;
          height: 100%;
          color: $white;

          & svg {
            width: rem(20px);
          }
        }
      }
      & .icon {
        & img {
          height: rem(30px);
        }
        & i {
          display: block;
          opacity: 0.5;
          margin: rem(-10px) 0;
          font-size: $font-size-base * 1.5;
        }
        & + .title {
          @if $enable-rtl {
            padding-right: $spacer * 0.75;
          } @else {
            padding-left: $spacer * 0.75;
          }
        }
      }
      & .title {
        flex: 1;
        font-weight: $font-weight-bold;
        font-size: rem(14px);
      }
      & .order {
        display: block;
        border-radius: $border-radius;
        background: var(--#{$prefix}app-theme);
        padding: rem(2px) rem(6px);
        color: var(--#{$prefix}app-theme-color);
        font-size: rem(12px);
      }
    }
    & .pos-sidebar-nav {
      & .nav.nav-tabs {
        padding: 0;

        & .nav-item {
          padding: 0;

          & .nav-link {
            border: none;
            border-radius: 0;
            background: var(--#{$prefix}pos-sidebar-header-nav-link-bg);
            padding: rem(12px) rem(15px);
            color: var(--#{$prefix}pos-sidebar-header-nav-link-color);
            font-size: rem(13px);

            &.active {
              background: var(
                --#{$prefix}pos-sidebar-header-nav-link-active-bg
              );
              color: var(--#{$prefix}pos-sidebar-header-nav-link-active-color);
            }
          }
        }
      }
    }
    & .pos-sidebar-body {
      flex: 1;
      margin: 0;
      padding: 0;
      overflow: scroll;

      & .pos-table {
        padding: 0 $spacer * 1.35;

        & .pos-table-row {
          position: relative;
          margin: 0;
          padding: $spacer * 1.35 0;

          & + .pos-table-row {
            border-top: 1px solid
              var(--#{$prefix}pos-sidebar-product-border-color);
          }

          & > div {
            padding: 0;

            & .input-group {
              &.qty {
                align-items: center;
                margin-top: 10px;
                width: 85px;

                & .form-control {
                  margin: 0;
                  border: 0;
                  background: none;
                  padding: 0;
                  height: rem(26px);
                  font-weight: $font-weight-bold;
                  line-height: 1;
                  text-align: center;
                }
                & .btn {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: none;
                  padding: 0;
                  width: rem(26px);
                  height: rem(26px);
                }
              }
            }
            & .price {
              font-weight: $font-weight-bold;
              font-size: $font-size-lg;
            }
            &.total-price {
              font-weight: $font-weight-bold;
              font-size: $font-size-lg;

              @if $enable-rtl {
                text-align: left;
              } @else {
                text-align: right;
              }
            }
            & a {
              display: inline-block;
            }
            & .pos-product-thumb {
              display: flex;

              & .img {
                border-radius: $border-radius-lg;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 60px;
                height: 60px;

                & + .info {
                  flex: 1;

                  @if $enable-rtl {
                    padding-right: rem(15px);
                  } @else {
                    padding-left: rem(15px);
                  }
                }
              }
              & .info {
                flex: 1;

                & .title {
                  font-weight: $font-weight-bold;
                  font-size: 14px;
                }
                & .single-price {
                  color: var(--#{$prefix}component-color);
                  font-weight: $font-weight-bold;
                }
              }
            }
          }
          & .pos-remove-confirmation {
            display: flex;
            position: absolute;
            top: $spacer * 0.5;
            right: $spacer * -0.7;
            bottom: $spacer * 0.5;
            left: $spacer * -0.7;
            align-items: center;
            z-index: 10;
            border-radius: var(--#{$prefix}border-radius-lg);
            background: var(--#{$prefix}pos-sidebar-product-confirmation-bg);
            padding: $spacer * 1.35;
            width: auto;
            max-width: initial;
            font-weight: $font-weight-bold;
            font-size: $font-size-lg;
          }
        }
      }
    }
    & .pos-sidebar-footer {
      position: relative;
      background: var(--#{$prefix}pos-sidebar-footer-bg);
      padding: $spacer $spacer;
      overflow: hidden;
      color: var(--#{$prefix}pos-sidebar-footer-color);

      @include media-breakpoint-up(lg) {
        border-radius: 0 0 $border-radius-lg $border-radius-lg;
      }
    }
    & .pos-order {
      display: flex;
      position: relative;
      padding: $spacer * 1.5;

      & .pos-order-product {
        display: flex;
        flex: 1;

        & .img {
          background-position: center;
          background-size: cover;
          width: rem(72px);
          height: rem(72px);
        }
        & > * + * {
          @if $enable-rtl {
            margin-right: $spacer;
          } @else {
            margin-left: $spacer;
          }
        }
      }
      & > * + * {
        @if $enable-rtl {
          margin-right: $spacer;
        } @else {
          margin-left: $spacer;
        }
      }

      & .pos-order-confirmation {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        margin: 0;
        background: rgba(var(--#{$prefix}body-bg-rgb), 0.97);
        padding: $spacer;

        & > * {
          position: relative;
        }
        & .btn {
          min-width: rem(60px);
        }
      }

      & + .pos-order {
        border-top: 1px solid var(--#{$prefix}border-color);
      }
    }
  }
  & .pos-header {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    align-items: center;
    z-index: $app-header-zindex;
    background: var(--#{$prefix}component-bg);
    padding: $spacer * 0.66 $spacer * 1.75;
    height: $pos-header-height;

    & .logo,
    & .time,
    & .nav {
      width: 33.33%;

      @include media-breakpoint-down(md) {
        width: 50%;
      }
    }
    & .logo {
      display: flex;

      & > div,
      & > a {
        display: flex;
        align-items: center;
        color: var(--#{$prefix}component-color);
        text-align: center;
        text-decoration: none;

        & .logo-img {
          & img {
            height: rem(32px);

            .dark-mode & {
              filter: invert(1);
            }
          }
        }
        & .logo-text {
          font-weight: 500;
          font-size: rem(16px);
          letter-spacing: rem(1px);

          @if $enable-rtl {
            margin-right: rem(15px);
          } @else {
            margin-left: rem(15px);
          }
        }
      }
    }
    & .time {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: $font-weight-bold;
      font-size: rem(20px);

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    & .nav {
      justify-content: flex-end;
      align-items: center;

      & .nav-item {
        & .nav-link {
          padding: $spacer * 0.333 $spacer;

          @include media-breakpoint-down(md) {
            padding: $spacer * 0.333 $spacer * 0.66;
          }
          & .nav-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: rem(-1px) 0;
            height: rem(20px);
            color: var(--#{$prefix}component-color);
            font-size: rem(20px);
          }
          &:hover,
          &:focus {
            opacity: 0.75;
          }
        }
      }
    }
  }
  & .pos-table-booking {
    display: block;
    margin-bottom: $spacer * 1.25;
    text-decoration: none;

    & .pos-table-booking-container {
      transition: box-shadow 0.1s linear;
      border-radius: $border-radius-lg;
      background: $gray-800;
      color: $white;

      &:hover {
        box-shadow: 0 0 0 5px rgba($gray-800, 0.5);
      }
      & .pos-table-booking-header {
        padding: $spacer;

        & .title {
          color: $gray-500;
          font-weight: $font-weight-bold;
        }
        & .no {
          font-weight: $font-weight-bold;
          font-size: $font-size-lg * 2.5;
        }
        & .desc {
          color: $gray-500;
          font-weight: $font-weight-bold;
        }
      }
      & .pos-table-booking-body {
        border-bottom-right-radius: $border-radius-lg;
        border-bottom-left-radius: $border-radius-lg;
        background: $gray-700;

        & .booking {
          display: flex;
          padding: $spacer * 0.333 $spacer;
          font-size: $font-size-sm;

          & .time {
            width: rem(60px);
            color: $gray-300;
            font-weight: $font-weight-bold;
          }
          & .info {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          & .status {
            display: flex;
            align-items: center;
            color: $gray-500;
            font-size: rem(6px);

            @if $enable-rtl {
              padding-right: $spacer;
            } @else {
              padding-left: $spacer;
            }

            &.upcoming {
              color: $warning;
            }
            &.in-progress {
              color: $success;
            }
          }
          &.highlight {
            background: $gray-800;
          }
          & + .booking {
            border-top: 1px solid $gray-800;
          }
        }
      }
    }
  }
  & .pos-table-row {
    display: flex;
    flex-wrap: wrap;
    margin: $spacer * -0.5;

    & .pos-table {
      flex: 0 0 25%;
      margin: 0;
      padding: $spacer * 0.5;
      width: 25%;
      height: 100%;

      @include media-breakpoint-down(xl) {
        flex: 0 0 33.33%;
        width: 33.33%;
      }
      @include media-breakpoint-down(md) {
        flex: 0 0 50%;
        width: 50%;
      }

      & .pos-table-container {
        display: block;
        position: relative;
        transition: all 0.1s linear;
        border-radius: var(--#{$prefix}border-radius-lg);
        background: var(--#{$prefix}component-bg);
        height: 100%;
        overflow: hidden;
        color: var(--#{$prefix}component-color);
        font-weight: $font-weight-bold;
        text-decoration: none;

        & .pos-table-status {
          display: flex;
          position: absolute;
          top: rem(5px);
          right: rem(5px);
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          background: var(--#{$prefix}component-bg);
          width: 8px;
          height: 8px;

          & i {
            font-size: rem(8px);
          }
        }
        & .pos-table-name {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: all 0.1s linear;
          padding: rem(15px);
          text-align: center;

          & .name {
            font-size: $font-size-lg;
          }
          & .no {
            margin-bottom: rem(-4px);
            font-size: rem(32px);
          }
          & .order {
            opacity: 0.5;
          }
          & > * {
            padding: 0;
          }
        }
        & .pos-table-info-row {
          display: flex;
          margin: 0 rem(-1px) 0 0;
          color: var(--#{$prefix}component-color);

          & .pos-table-info-col {
            flex: 0 0 50%;
            margin: 0 auto;
            padding: rem(1px) rem(1px) 0 0;
            width: 50%;

            & .pos-table-info-container {
              display: flex;
              align-items: center;
              background: var(--#{$prefix}component-disabled-bg);
              padding: rem(2px) rem(5px);
              height: 100%;
            }
          }
          & .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: rem(24px);
            height: rem(24px);

            & + .text {
              margin-left: rem(2px);
            }
          }
          & .text {
            flex: 1;
            font-weight: $font-weight-bold;
          }
          & + .pos-table-col-info {
            border-top: 1px solid $gray-100;
          }
        }
        & + .pos-table-col {
          border-left: 1px solid $gray-100;
        }
      }
      &.selected:not(.disabled) {
        & .pos-table-container {
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            border: 20px solid var(--#{$prefix}app-theme);
            border-right-color: transparent;
            border-bottom-color: transparent;
            content: "";
          }
          &:after {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            justify-content: center;
            align-items: center;
            z-index: 10;
            width: rem(25px);
            height: rem(25px);
            content: "\f00c";
            color: var(--#{$prefix}app-theme-color);
            font-size: rem(14px);

            @include fontawesome();
          }
        }
      }
      &.disabled {
        & .pos-table-container {
          cursor: not-allowed;
          color: rgba(var(--#{$prefix}component-color-rgb), 0.35);

          & .pos-table-status {
            background: var(--#{$prefix}component-disabled-bg);
          }
          & .pos-table-info-row {
            opacity: 0.25;
          }
        }
      }
      &.available {
        & .pos-table-container {
          cursor: not-allowed;

          & .pos-table-status {
            background: var(--#{$prefix}app-theme);
          }
          & .pos-table-info-row {
            color: transparent;
          }
        }
      }
      &.in-use {
        & .pos-table-container {
          & .pos-table-status {
            background: var(--#{$prefix}warning);
          }
        }
      }
    }
  }
  & .pos-stock-product {
    padding: $spacer * 0.5;
    height: 100%;

    & .pos-stock-product-container {
      box-shadow: $box-shadow;
      border-radius: $border-radius-lg;
      background: var(--#{$prefix}component-bg);
      height: 100%;
      overflow: hidden;

      & .product {
        display: flex;
        flex-direction: column;
        height: 100%;

        & .product-img {
          & .img {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            padding-top: 75%;

            @include media-breakpoint-down(md) {
              padding-top: 56.25%;
            }
          }
        }
        & .product-info {
          flex: 1;
          padding: $spacer $spacer * 1.25;

          & .title {
            font-weight: $font-weight-bold;
            font-size: $font-size-lg;
          }
          & .desc {
            margin-bottom: $spacer;
            color: rgba(var(--#{$prefix}component-color-rgb), 0.5);
          }
        }
        & .product-option {
          margin: 0 0 $spacer * 0.333;

          & .option {
            display: flex;
            flex: 1;
            align-items: center;
            padding: rem(0);

            & .option-label {
              width: rem(90px);
              font-weight: $font-weight-bold;

              @if $enable-rtl {
                padding-left: $spacer * 0.66;
              } @else {
                padding-right: $spacer * 0.66;
              }
            }
            & .option-input {
              flex: 1;

              & .form-control {
                padding: $spacer * 0.25 $spacer * 0.66;
              }
              & .form-switch {
                & .form-check-input {
                  &:checked {
                    border-color: $teal;
                    background-color: $teal;
                  }
                }
              }
            }
            & + .option {
              padding-top: $spacer;
            }
          }
        }
        & .product-action {
          display: flex;

          & .btn {
            flex: 1;
            border-radius: 0;
            padding: $spacer * 0.75 0;
          }
        }
      }
    }
  }
  &.pos-sidebar-mobile-toggled {
    & .pos-sidebar {
      @include media-breakpoint-down(lg) {
        display: flex;
      }
    }
  }
}

@if $enable-dark-mode {
  @include color-mode(dark, true) {
    .pos,
    & {
      --#{$prefix}pos-sidebar-bg: #{$pos-sidebar-bg-dark};
      --#{$prefix}pos-sidebar-header-bg: #{$pos-sidebar-header-bg-dark};
      --#{$prefix}pos-sidebar-header-color: #{$pos-sidebar-header-color-dark};
      --#{$prefix}pos-sidebar-header-back-btn-bg: #{$pos-sidebar-header-back-btn-bg-dark};
      --#{$prefix}pos-sidebar-header-nav-link-bg: #{$pos-sidebar-header-nav-link-bg-dark};
      --#{$prefix}pos-sidebar-header-nav-link-color: #{$pos-sidebar-header-nav-link-color-dark};
      --#{$prefix}pos-sidebar-header-nav-link-active-bg: #{$pos-sidebar-header-nav-link-active-bg-dark};
      --#{$prefix}pos-sidebar-header-nav-link-active-color: #{$pos-sidebar-header-nav-link-active-color-dark};
      --#{$prefix}pos-sidebar-footer-bg: #{$pos-sidebar-footer-bg-dark};
      --#{$prefix}pos-sidebar-footer-color: #{$pos-sidebar-footer-color-dark};
      --#{$prefix}pos-sidebar-product-border-color: #{$pos-sidebar-product-border-color-dark};
      --#{$prefix}pos-sidebar-product-confirmation-bg: #{$pos-sidebar-product-confirmation-bg-dark};
    }
  }
}

.pos-mobile-sidebar-toggler {
  display: none;
  position: fixed;
  right: rem(12px);
  bottom: rem(60px);
  justify-content: center;
  align-items: center;
  z-index: 1020;
  box-shadow: $box-shadow-lg;
  border-radius: 50px;
  background: var(--#{$prefix}component-color);
  padding: rem(10px);
  width: rem(60px);
  height: rem(60px);
  color: var(--#{$prefix}component-bg);
  text-decoration: none;

  @include media-breakpoint-down(lg) {
    display: flex;
  }

  &:hover {
    color: $gray-600;
  }
  & .img {
    display: block;
    margin: 0 auto;
    width: rem(32px);
    height: rem(32px);
  }
  & .badge {
    display: flex;
    position: absolute;
    top: rem(0px);
    right: rem(0px);
    justify-content: center;
    align-items: center;
    border-radius: $border-radius * 5;
    background-color: var(--#{$prefix}app-theme);
    padding: 0;
    width: rem(20px);
    height: rem(20px);
    color: var(--#{$prefix}app-theme-color);
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
  }
}

.modal.modal-pos {
  & .close {
    position: absolute;
    top: rem(25px);
    z-index: 10;

    @if $enable-rtl {
      left: rem(25px);
    } @else {
      right: rem(25px);
    }
  }
  & .modal-pos-product {
    @include media-breakpoint-up(lg) {
      display: flex;

      & .modal-pos-product-img,
      & .modal-pos-product-info {
        flex: 0 0 50%;
        width: 50%;
        max-width: 50%;
      }
    }
    & .modal-pos-product-img {
      @include media-breakpoint-up(lg) {
        @if ($enable-rtl) {
          padding-left: $spacer * 1;
        } @else {
          padding-right: $spacer * 1;
        }
      }
      @include media-breakpoint-down(lg) {
        padding-bottom: 0;
      }

      & .img {
        border-radius: var(--#{$prefix}border-radius-lg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        min-height: rem(250px);

        @include media-breakpoint-up(lg) {
          min-height: rem(300px);

          @if ($enable-rtl) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          } @else {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        @include media-breakpoint-down(lg) {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    & .modal-pos-product-info {
      padding: $spacer * 1.5;

      @include media-breakpoint-up(lg) {
        @if ($enable-rtl) {
          padding-right: $spacer * 1;
        } @else {
          padding-left: $spacer * 1;
        }
      }

      & .option-list {
        display: flex;
        flex-wrap: wrap;
        margin: $spacer * -0.333;
        padding: $spacer * 0.333 0;

        & .option {
          flex: 0 0 33.33%;
          padding: $spacer * 0.333;
          width: 33.33%;
          max-width: 33.33%;

          & .option-input {
            display: none;

            &:checked {
              & + .option-label {
                border: 2px solid var(--#{$prefix}body-color);
                padding: rem(9px) rem(7px);
                color: var(--#{$prefix}body-color);

                & .option-text {
                  color: var(--#{$prefix}body-color);
                }
              }
            }
          }
          & .option-label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--#{$prefix}border-color);
            border-radius: var(--#{$prefix}border-radius-lg);
            padding: rem(10px) rem(8px);
            height: 100%;

            & .option-text {
              display: block;
              color: rgba(var(--#{$prefix}body-color-rgb), 0.5);
              font-weight: $font-weight-bold;
              line-height: $headings-line-height;
              text-align: center;
            }
            & .option-price {
              margin-top: $spacer * 0.25;
              font-weight: $font-weight-bold;
              font-size: $font-size-lg;
              line-height: $headings-line-height;
            }
          }
        }
      }
    }
  }
}
