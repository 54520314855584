.widget-todolist {
  background: var(--#{$prefix}component-bg);
  color: var(--#{$prefix}component-color);

  @include clearfix();

  & .widget-todolist-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--#{$prefix}component-border-color);
    padding: $spacer * 0.66 $spacer;

    & .widget-todolist-header-title {
      flex: 1;
      margin: 0;
      color: var(--#{$prefix}component-color);
      font-weight: $font-weight-base;
      font-size: $h4-font-size;
    }
    & .widget-todolist-header-total {
      color: var(--#{$prefix}component-color);
      font-weight: $font-weight-bold;
      font-size: $h4-font-size;
      text-align: center;

      & small {
        display: block;
        font-size: $font-size-base * 0.8;
        line-height: 1;
      }
    }
  }
  & .widget-todolist-body {
    margin-bottom: rem(20px);
    border-bottom: 1px solid var(--#{$prefix}component-border-color);
  }
  & .widget-todolist-item {
    display: flex;

    & .widget-todolist-icon {
      border-top: 1px solid var(--#{$prefix}component-border-color);
      padding: $spacer * 0.66 $spacer;
      text-align: center;
    }
    & .widget-todolist-input {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: $spacer * 0.66 $spacer;
      width: rem(48px);

      & .form-check {
        @if $enable-rtl {
          padding-right: rem(18px);
        } @else {
          padding-left: rem(18px);
        }

        & .form-check-input {
          @if $enable-rtl {
            margin-right: rem(-18px);
          } @else {
            margin-left: rem(-18px);
          }
        }
      }

      & + div {
        @if $enable-rtl {
          padding-right: 0;
        } @else {
          padding-left: 0;
        }
      }
    }
    & .widget-todolist-content {
      flex: 1;
      border-top: 1px solid var(--#{$prefix}component-border-color);
      padding: $spacer * 0.66 $spacer;
    }
    &:first-child {
      & .widget-todolist-content,
      & .widget-todolist-icon {
        border-top: none;
      }
    }
  }
}
