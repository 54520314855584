@include media-breakpoint-up(lg) {
  .app-with-hover-sidebar:not(.app-sidebar-minified) {
    & .app-sidebar:not(.app-sidebar-end) {
      bottom: auto;
      transition-duration: 150ms;
      transition-property: width, box-shadow;
      box-shadow: inset -12px 6px 12px rgba($black, 0.14);
      width: $app-sidebar-width-hover;
      min-height: 100vh;
      overflow: hidden;

      & .app-sidebar-content {
        width: $app-sidebar-width;
      }
      &:hover {
        box-shadow: 12px 6px 12px rgba($black, 0.14);
        width: $app-sidebar-width;

        & + .app-sidebar-bg {
          width: $app-sidebar-width;
        }
      }
    }
    & .app-sidebar-bg {
      transition-duration: 150ms;
      transition-property: width;
      width: $app-sidebar-width-hover;
    }
    & .app-content {
      @if $enable-rtl {
        margin-right: $app-sidebar-width-hover;
      } @else {
        margin-left: $app-sidebar-width-hover;
      }
    }
    &.app-sidebar-fixed {
      & .app-sidebar {
        bottom: 0;
      }
    }
    &.app-with-wide-sidebar {
      & .app-sidebar {
        width: $app-sidebar-width-hover;

        & .app-sidebar-content {
          width: $app-sidebar-width-wide;
        }

        &:hover {
          width: $app-sidebar-width-wide;

          & + .app-sidebar-bg {
            width: $app-sidebar-width-wide;
          }
        }
      }
      & .app-sidebar-bg {
        width: $app-sidebar-width-hover;
      }
      & .app-content {
        @if $enable-rtl {
          margin-right: $app-sidebar-width-hover;
        } @else {
          margin-left: $app-sidebar-width-hover;
        }
      }
    }
  }
}
