.timeline {
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;

  &:before {
    position: absolute;
    top: rem(5px);
    bottom: rem(5px);
    background: $gray-700;
    width: rem(5px);
    content: "";

    @if $enable-rtl {
      right: 20%;
      margin-right: rem(-2.5px);
    } @else {
      left: 20%;
      margin-left: rem(-2.5px);
    }
    @include media-breakpoint-down(md) {
      @if $enable-rtl {
        right: 15%;
      } @else {
        left: 15%;
      }
    }
    @include media-breakpoint-down(sm) {
      @if $enable-rtl {
        right: 50%;
      } @else {
        left: 50%;
      }
    }
  }
  & .timeline-item {
    position: relative;
    padding: 20px 0;
    min-height: 50px;
  }
  & img {
    display: block;
    max-width: 100%;
  }
  & .timeline-time {
    position: absolute;
    top: rem(30px);
    width: 18%;
    text-align: right;

    @if $enable-rtl {
      right: 0;
      text-align: left;
    } @else {
      left: 0;
      text-align: right;
    }
    @include media-breakpoint-down(md) {
      width: 12%;
    }
    @include media-breakpoint-down(sm) {
      width: 50%;

      @if $enable-rtl {
        padding-left: rem(20px);
        text-align: left;
      } @else {
        padding-right: rem(20px);
        text-align: right;
      }
    }

    & .date {
      display: block;
      color: rgba(var(--#{$prefix}component-color-rgb), 0.85);
      font-weight: $font-weight-bold;
    }
    & .time {
      display: block;
      color: rgba(var(--#{$prefix}component-color-rgb), 0.85);
      font-weight: $font-weight-bold;
      font-size: $h2-font-size;
      line-height: rem(24px);
    }
  }
  & .timeline-icon {
    position: absolute;
    top: rem(40px);
    width: 10%;
    text-align: center;

    @if $enable-rtl {
      right: 15%;
    } @else {
      left: 15%;
    }
    @include media-breakpoint-down(md) {
      @if $enable-rtl {
        right: 10%;
      } @else {
        left: 10%;
      }
    }
    @include media-breakpoint-down(sm) {
      @if $enable-rtl {
        right: 45%;
      } @else {
        left: 45%;
      }
    }

    & a {
      display: inline-block;
      transition: border-color 0.2s linear;
      border: 5px solid $gray-700;
      border-radius: $border-radius-lg * 4;
      background: var(--#{$prefix}body-bg);
      width: rem(20px);
      height: rem(20px);
      color: $white;
      font-size: rem(14px);
      line-height: rem(10px);
      text-decoration: none;
    }
  }
  & .timeline-content {
    position: relative;
    border-radius: $border-radius-lg;
    background: var(--#{$prefix}component-bg);
    max-width: rem(640px);
    font-size: rem(14px);

    @if $enable-rtl {
      margin-right: 23%;
    } @else {
      margin-left: 23%;
    }
    @include media-breakpoint-down(lg) {
      @if $enable-rtl {
        margin-right: 25%;
        margin-left: 5%;
      } @else {
        margin-right: 5%;
        margin-left: 25%;
      }
    }
    @include media-breakpoint-down(md) {
      @if $enable-rtl {
        margin-right: 20%;
        margin-left: 0;
      } @else {
        margin-right: 0;
        margin-left: 20%;
      }
    }
    @include media-breakpoint-down(sm) {
      margin-top: rem(60px);

      @if $enable-rtl {
        margin-right: 0;
      } @else {
        margin-left: 0;
      }
    }

    &:before {
      display: block;
      position: absolute;
      top: rem(20px);
      border: 10px solid transparent;
      content: "";

      @if $enable-rtl {
        right: rem(-20px);
        border-left-color: var(--#{$prefix}component-bg);
      } @else {
        left: rem(-20px);
        border-right-color: var(--#{$prefix}component-bg);
      }
      @include media-breakpoint-down(sm) {
        top: rem(-20px);
        border-bottom-color: var(--#{$prefix}component-bg);

        @if $enable-rtl {
          right: 50%;
          margin-right: rem(-10px);
          border-left-color: transparent;
        } @else {
          left: 50%;
          margin-left: rem(-10px);
          border-right-color: transparent;
        }
      }
    }
  }
  & .timeline-header {
    display: flex;
    align-items: center;
    padding: $spacer;

    & .userimage {
      border-radius: $border-radius-lg * 6;
      width: rem(40px);
      height: rem(40px);
      overflow: hidden;

      @if $enable-rtl {
        margin-left: $spacer * 0.8;
      } @else {
        margin-right: $spacer * 0.8;
      }
    }
    & .username {
      flex: 1;
      color: var(--#{$prefix}component-color);
      font-weight: $font-weight-bold;
      font-size: $font-size-lg;

      & a {
        color: var(--#{$prefix}component-color);
        text-decoration: none;
      }
    }
    & + .timeline-body {
      padding-top: 0;
    }
  }
  & .timeline-body {
    padding: $spacer;
  }
}
