.widget {
  margin-bottom: rem(20px);
  background: var(--#{$prefix}component-bg);
  padding: 0;
  overflow: hidden;
  color: inherit;

  & .widget-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--#{$prefix}component-border-color);
    color: var(--#{$prefix}component-color);

    & .widget-header-title {
      flex: 1;
      margin: 0;
      padding: $spacer * 0.66 $spacer;
      font-size: $font-size-base;
    }
    & .widget-header-icon {
      padding: $spacer * 0.66 $spacer;

      & + .widget-header-icon {
        @if $enable-rtl {
          padding-right: 0;
        } @else {
          padding-left: 0;
        }
      }
    }
  }
  & .widget-body {
    padding: $spacer;
  }
}
