.tagit {
  &.ui-widget {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    border: 1px solid var(--#{$prefix}component-border-color);
    border-radius: $border-radius;
    background: var(--#{$prefix}component-bg);
    padding: $input-padding-y * 0.666 $input-padding-x 0;
    min-height: $input-height;
    font-family: inherit;

    & li {
      float: none;
      margin: 0 $input-padding-y * 0.666 $input-padding-y * 0.5 0;

      &.tagit-choice {
        display: flex;
        align-items: center;
        border: none;
        border-radius: $border-radius;
        background: var(--#{$prefix}component-secondary-bg);
        padding: $input-padding-y * 0.4 $input-padding-x;
        color: var(--#{$prefix}component-color);
        font-weight: $input-font-weight;
        line-height: $input-line-height;

        & .tagit-label {
          flex: 1;

          @if $enable-rtl {
            padding-left: $input-padding-x;
          } @else {
            padding-right: $input-padding-x;
          }
        }
        & .tagit-close {
          position: relative;
          top: 0;
          right: 0;
          margin: 0;
          margin: rem(-5px) rem(-8px);
          padding: rem(5px) rem(8px);
          color: inherit;
          line-height: 1;
          text-align: center;

          & .ui-icon-close {
            margin-top: rem(0);
            background: none;
            width: auto;
            height: auto;
            text-indent: 0;

            @include fontawesome();

            &:before {
              opacity: 0.5;
              content: "\f00d";
              color: inherit;
              font-size: inherit;
            }
          }
          &:hover,
          &:focus {
            & .ui-icon-close {
              &:before {
                opacity: 0.75;
                color: inherit;
              }
            }
          }
        }
      }
      &.tagit-new {
        padding: 0;

        & input {
          height: 100%;
          min-height: ($input-padding-y * 0.4) * 2 + $input-font-size *
            $input-line-height;
          color: var(--#{$prefix}component-color);
          font-weight: $input-font-weight;
          font-size: $input-font-size;
          font-family: inherit;
        }
      }
    }

    &.white {
      & li.tagit-choice {
        box-shadow: inset 0 0 0 1px $gray-300;
        background: $white;
        color: $gray-800;
      }
    }
    &.inverse {
      & li.tagit-choice {
        background: $gray-700;
        color: $white;
      }
    }
    &.primary {
      & li.tagit-choice {
        background: $blue;
        color: $white;
      }
    }
    &.info {
      & li.tagit-choice {
        background: $info;
        color: $white;
      }
    }
    &.success {
      & li.tagit-choice {
        background: $teal;
        color: $white;
      }
    }
    &.warning {
      & li.tagit-choice {
        background: $warning;
        color: $white;
      }
    }
    &.danger {
      & li.tagit-choice {
        background: $danger;
        color: $white;
      }
    }
  }
}
