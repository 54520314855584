.wysihtml5-sandbox {
  border: none !important;
  border-radius: 0 !important;
  padding: $spacer !important;
  width: 100% !important;
}
ul.wysihtml5-toolbar {
  body & {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--#{$prefix}component-border-color);
    background: var(--#{$prefix}light);
    padding: $spacer $spacer $spacer * 0.5 !important;

    & > li {
      float: none;
      margin: 0 $spacer * 0.5 $spacer * 0.5 0;
    }
    & .btn.btn-default {
      border-color: var(--#{$prefix}component-border-color);
      background: var(--#{$prefix}component-bg);

      &:hover,
      &:focus {
        background: var(--#{$prefix}component-hover-bg);
      }

      &.wysihtml5-command-active {
        box-shadow: none;
        border-color: var(--#{$prefix}component-active-bg);
        background: var(--#{$prefix}component-active-bg);
        color: var(--#{$prefix}component-active-color);
      }
    }
    & .glyphicon {
      @include fontawesome();

      &.glyphicon-quote:before {
        display: none;
      }
      &.glyphicon-quote:after {
        content: "\f10d";
      }
      &.glyphicon-list:before {
        content: "\f03a";
      }
      &.glyphicon-indent-right:before {
        content: "\f038";
      }
      &.glyphicon-indent-left:before {
        content: "\f036";
      }
      &.glyphicon-th-list:before {
        content: "\f00b";
      }
      &.glyphicon-share:before {
        content: "\f064";
      }
      &.glyphicon-picture:before {
        content: "\f03e";
      }
    }
    & .dropdown-menu {
      margin: 0;
      padding: $dropdown-padding-y $dropdown-padding-x;
      list-style-type: none;

      & > li {
        & > a {
          display: block;
          padding: $dropdown-item-padding-y $dropdown-item-padding-x;
          color: $dropdown-link-color;
          text-decoration: none;

          &:hover,
          &:focus {
            background: $dropdown-link-hover-bg;
            color: $dropdown-link-hover-color;
          }
        }
      }
    }
  }
}
