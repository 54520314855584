.irs {
  font-family: inherit !important;

  & .irs {
    & .irs-line {
      top: rem(30px);
      border: none;
      background: var(--#{$prefix}component-border-color);
      height: rem(4px);
    }
    & .irs-to,
    & .irs-from,
    & .irs-single {
      background: $gray-800;
      font-weight: $font-weight-bold;
      font-size: $font-size-sm;

      &:before {
        border-top-color: $gray-800;
      }
      .dark-mode & {
        background: $gray-600;

        &:before {
          border-top-color: $gray-600;
        }
      }
    }
    & .irs-min,
    & .irs-max {
      background: $gray-300;
      color: $dark;
      font-weight: $font-weight-bold;
      font-size: $font-size-sm;
    }
  }

  &.irs--flat {
    & .irs-bar {
      top: rem(30px);
      background: var(--#{$prefix}component-active-bg);
      height: rem(4px);
    }
    & .irs-handle {
      top: rem(25px);
      height: rem(14px);

      & > i {
        &:first-child {
          margin-left: rem(-2px);
          background: $component-active-bg;
          width: rem(4px);
        }
      }
      &:hover,
      &:focus,
      &.state_hover {
        & > i {
          &:first-child {
            background: shade-color($component-active-bg, 25%);
          }
        }
      }
    }
  }
  &.irs--big {
    height: rem(40px);

    & .irs-bar {
      top: rem(30px);
      box-shadow: none;
      background: $component-active-bg;
      height: rem(4px);
    }
    & .irs-handle {
      top: rem(21px);
      box-shadow: 0 2px 5px rgba($gray-900, 0.2);
      border: none;
      background: $white;
      width: rem(20px);
      height: rem(20px);

      &:hover,
      &:focus,
      &.state_hover {
        box-shadow: 0 2px 5px rgba($gray-900, 0.4);
        background: $white;
      }
    }
  }
}
