.btn.btn-social,
.btn.btn-social-icon {
  & > :first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.btn.btn-social-icon {
  &.btn-lg {
    width: rem(39px);
    height: rem(39px);
  }
}
