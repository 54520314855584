.login.login-v1 {
  display: flex;
  align-items: center;
  background-image: var(--#{$prefix}app-login-bg-cover);
  background-size: cover;
  width: 100%;
  min-height: 100vh;

  @include media-breakpoint-down(md) {
    background: $gray-800;
  }

  & .login-container {
    flex: 1;

    @include media-breakpoint-down(md) {
      padding: 0 rem(25px);
    }

    & .login-header {
      display: flex;
      position: relative;
      align-items: center;
      margin: 0 auto;
      margin-bottom: rem(15px);
      padding: 0;
      max-width: $login-v1-container-width;

      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }

      & .brand {
        flex: 1;
        padding: 0;
        color: var(--#{$prefix}component-color);
        font-weight: 300;
        font-size: rem(28px);

        @include media-breakpoint-down(md) {
          color: $white;
          font-size: rem(24px);
        }

        & .logo {
          position: relative;
          border: 14px solid transparent;
          border-color: transparent rgba($black, 0.15) rgba($black, 0.3);
          border-radius: $border-radius-lg;
          background-color: var(--#{$prefix}app-theme);
          width: rem(28px);
          height: rem(28px);
          font-size: 0;

          @if $enable-rtl {
            margin-left: $spacer * 0.75;
          } @else {
            margin-right: $spacer * 0.75;
          }
          @include media-breakpoint-down(md) {
            border-width: rem(12px);
            width: rem(24px);
            height: rem(24px);
          }
        }
        & small {
          display: block;
          color: rgba(var(--#{$prefix}component-color-rgb), 0.75);
          font-weight: $font-weight-normal;
          font-size: rem(13px);

          @include media-breakpoint-down(md) {
            color: $gray-500;
            font-size: rem(12px);
          }
        }
      }
      & .icon {
        color: $gray-400;

        @include media-breakpoint-down(md) {
          color: $gray-700;
        }

        & i {
          font-size: rem(64px);

          @include media-breakpoint-down(md) {
            font-size: rem(48px);
          }
        }
      }
    }
    & .login-body {
      background: $gray-800;
      padding: rem(30px);

      @include media-breakpoint-down(md) {
        background: none;
        padding: rem(25px) 0;
      }

      & .login-content {
        margin: 0 auto;
        padding: 0;
        max-width: $login-v1-container-width;
        color: $gray-500;

        & .form-control {
          border-color: transparent;
          background: $gray-700;
          color: $white;

          &::placeholder {
            color: $gray-500;
          }
        }
        & .form-floating {
          & .form-control {
            &::placeholder {
              color: transparent;
            }
            &:focus {
              box-shadow: none;

              & + label {
                padding-top: rem(5px);
                color: inherit;

                &:after {
                  background: none;
                }
              }
            }
          }
        }
        & .form-check-input {
          border-color: transparent;
          background-color: $gray-700;

          &:checked {
            background-color: var(--#{$prefix}app-theme);
          }
        }
      }
    }
  }
}
.login.login-v2 {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius;
  min-height: 100vh;
  color: $white;

  & .login-container {
    position: relative;
    flex: 1;
    padding: 0 rem(20px);
    max-width: $login-v2-container-width;
  }
  & .login-header {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: rem(20px);

    & .brand {
      flex: 1;
      padding: 0;
      font-weight: 300;
      font-size: rem(28px);

      @include media-breakpoint-down(md) {
        color: $white;
        font-size: rem(24px);
      }

      & .logo {
        position: relative;
        border: 14px solid transparent;
        border-color: transparent rgba($black, 0.15) rgba($black, 0.3);
        border-radius: $border-radius-lg;
        background-color: var(--#{$prefix}app-theme);
        width: rem(28px);
        height: rem(28px);
        font-size: 0;

        @if $enable-rtl {
          margin-left: $spacer * 0.75;
        } @else {
          margin-right: $spacer * 0.75;
        }
        @include media-breakpoint-down(md) {
          border-width: rem(12px);
          width: rem(24px);
          height: rem(24px);
        }
      }
      & small {
        display: block;
        opacity: 0.5;
        font-weight: $font-weight-bold;
        font-size: rem(13px);

        @include media-breakpoint-down(md) {
          color: $gray-500;
          font-size: rem(12px);
        }
      }
    }
    & .icon {
      opacity: 0.75;

      & i {
        font-size: rem(56px);

        @include media-breakpoint-down(md) {
          font-size: rem(48px);
        }
      }
    }
  }
  & .login-content {
    & .form-control {
      &:focus {
        box-shadow: 0 0 0 5px rgba($white, 0.35);
      }
    }
    & .form-check-input {
      &:checked {
        background-color: var(--#{$prefix}app-theme);
      }
    }
  }
}
.login.login-with-news-feed,
.register.register-with-news-feed {
  min-height: 100vh;

  & .news-feed {
    position: fixed;
    top: 0;
    right: $login-register-news-feed-container-width;
    bottom: 0;
    left: 0;
    transform: translateZ(0);
    overflow: hidden;

    @if $enable-rtl {
      right: 0;
      left: rem(500px);
    }
    @include media-breakpoint-down(lg) {
      right: $login-register-news-feed-container-width-sm;

      @if $enable-rtl {
        right: 0;
        left: $login-register-news-feed-container-width-sm;
      }
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
    & .news-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    & .news-caption {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 20;
      background: linear-gradient(
        to bottom,
        (rgba($black, 0) 0%, rgba($black, 1) 100%)
      );
      padding: rem(60px) rem(60px) rem(45px);
      color: rgba($white, 0.85);
      font-weight: 300;
      font-size: rem(18px);
      letter-spacing: rem(0.25px);

      @include media-breakpoint-down(lg) {
        padding: rem(45px) rem(45px) rem(30px);
        font-size: rem(16px);
      }

      & .caption-title {
        color: $white;
        font-weight: 300;
        font-size: rem(36px);

        @include media-breakpoint-down(lg) {
          font-size: rem(28px);
        }
      }
    }
  }
  & .login-container,
  & .register-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--#{$prefix}component-bg);
    padding: rem(60px);
    width: $login-register-news-feed-container-width;
    min-height: 100vh;

    @if $enable-rtl {
      margin-right: auto;
    } @else {
      margin-left: auto;
    }
    @include media-breakpoint-down(lg) {
      padding: rem(45px);
      width: $login-register-news-feed-container-width-sm;
    }
    @include media-breakpoint-down(md) {
      padding: rem(30px);
      width: auto;
    }
  }
  & .login-header {
    display: flex;
    position: relative;
    align-items: center;

    & .brand {
      flex: 1;
      padding: 0;
      color: var(--#{$prefix}component-color);
      font-weight: 300;
      font-size: rem(28px);

      @include media-breakpoint-down(md) {
        font-size: rem(22px);
      }

      & .logo {
        border: 14px solid transparent;
        border-color: transparent rgba($black, 0.15) rgba($black, 0.3);
        border-radius: $border-radius-lg;
        background-color: var(--#{$prefix}app-theme);
        width: rem(28px);
        height: rem(28px);
        font-size: 0;

        @if $enable-rtl {
          margin-left: rem(10px);
        } @else {
          margin-right: rem(10px);
        }
        @include media-breakpoint-down(md) {
          border-width: 12px;
          width: rem(24px);
          height: rem(24px);
        }
      }
      & small {
        display: block;
        color: rgba(var(--#{$prefix}component-color-rgb), 0.5);
        font-weight: $font-weight-normal;
        font-size: rem(14px);

        @include media-breakpoint-down(md) {
          font-size: rem(13px);
        }
      }
    }
    & .icon {
      color: rgba(var(--#{$prefix}component-color-rgb), 0.25);

      & i {
        font-size: rem(64px);

        @include media-breakpoint-down(md) {
          font-size: rem(52px);
        }
      }
    }
  }
}

.login-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & .login-cover-bg,
  & .login-cover-img {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  & .login-cover-bg {
    background: linear-gradient(
      to bottom,
      (rgba($black, 0.45) 0%, rgba($black, 0.9))
    );
  }
  & .login-cover-img {
    transition: background 0.2s ease;
  }
}
.login-bg-list {
  display: flex;
  position: absolute;
  right: rem(20px);
  bottom: rem(20px);
  flex-wrap: wrap;
  z-index: 1020;
  margin: 0;
  padding: 0 0 0 rem(20px);
  list-style-type: none;

  & .login-bg-list-item {
    margin: rem(5px);
    border-radius: 66px;
    width: rem(60px);
    height: rem(60px);

    @include media-breakpoint-down(md) {
      width: rem(46px);
      height: rem(46px);
    }
    & .login-bg-list-link {
      display: block;
      transition: all 0.2s ease;
      border-radius: 66px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding-top: 100%;
      overflow: hidden;
    }
    &:hover,
    &:focus {
      & .login-bg-list-link {
        box-shadow: 0 0 0 4px rgba($white, 0.45);
      }
    }
    &.active {
      position: relative;
      z-index: 1020;
      border-color: $white;

      & .login-bg-list-link {
        box-shadow: 0 0 0 4px rgba($white, 0.9);
      }
    }
  }
}
