.navbar {
  &.navbar-sticky {
    border-right: 1px solid var(--#{$prefix}component-border-color);
    padding: 0;

    @if $enable-rtl {
      margin-left: $spacer * 0.5;
    } @else {
      margin-right: $spacer * 0.5;
    }

    & .nav {
      position: sticky;
      top: $app-header-height + $app-content-padding-y;
      flex-direction: column;
      width: 100%;

      & .nav-link {
        border-right: 2px solid transparent;
        padding: $nav-link-padding-y * 0.5 $nav-link-padding-x;
        color: rgba(var(--#{$prefix}body-color-rgb), 0.5);

        @if $enable-rtl {
          padding-left: $nav-link-padding-x + $spacer * 0.5;
        } @else {
          padding-right: $nav-link-padding-x + $spacer * 0.5;
        }

        &:hover {
          color: rgba(var(--#{$prefix}body-color-rgb), 1);
        }
        &.active {
          border-right-color: rgba(var(--#{$prefix}body-color-rgb), 1);
          color: rgba(var(--#{$prefix}body-color-rgb), 1);
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}
