.app-sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: $app-sidebar-zindex;
  background: var(--#{$prefix}app-sidebar-bg);
  padding-top: $app-header-height;
  width: $app-sidebar-width;

  @if $enable-rtl {
    right: 0;
  } @else {
    left: 0;
  }
  @include media-breakpoint-down(md) {
    position: fixed;
    left: $app-sidebar-width * -1;
    z-index: $app-header-zindex + 1;
    padding-top: 0;

    @if $enable-rtl {
      right: $app-sidebar-width * -1;
      left: auto;
    }
  }

  & .menu {
    & .menu-profile {
      position: relative;
      background: var(--#{$prefix}app-sidebar-profile-bg);
      padding: $app-sidebar-component-padding-x;
      overflow: hidden;
      color: var(--#{$prefix}app-sidebar-profile-color);

      & .menu-profile-link {
        display: block;
        margin: -$app-sidebar-component-padding-x;
        padding: $app-sidebar-component-padding-x;
        color: var(--#{$prefix}app-sidebar-profile-color);
        font-weight: $app-sidebar-profile-font-weight;
        text-decoration: none;

        &:hover {
          & .menu-profile-cover {
            &.with-shadow {
              &:before {
                opacity: $app-sidebar-profile-hover-opacity;
              }
            }
          }
        }
      }
      & .menu-profile-image {
        display: flex;
        position: relative;
        align-items: center;
        margin-bottom: $app-sidebar-profile-img-margin-y;
        border-radius: $app-sidebar-profile-img-border-radius;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: $app-sidebar-profile-img-width;
        height: $app-sidebar-profile-img-height;
        overflow: hidden;

        & img {
          max-width: 100%;
          max-height: 100%;
        }
        &.menu-profile-image-icon {
          & i {
            display: block;
            float: none;
            margin: 0 auto rem(-12px);
            width: auto;
            font-size: rem(24px);
            text-align: center;
          }
        }
      }
      & .menu-profile-cover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: $app-sidebar-profile-bg-image;
        background-size: cover;
        background-repeat: no-repeat;

        &.with-shadow {
          &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: all 0.2s linear;
            background: rgba(var(--#{$prefix}app-sidebar-profile-bg-rgb), 0.75);
            content: "";
          }
        }
      }
      & .menu-profile-info {
        position: relative;
        font-size: $font-size-lg;

        & small {
          display: block;
          margin-bottom: rem(-5px);
          color: rgba(var(--#{$prefix}app-sidebar-profile-color-rgb), 0.75);
          font-weight: $font-weight-base;
          font-size: $font-size-base;
        }
      }
    }
    & .menu-header {
      margin: 0;
      padding: rem(15px) rem(20px) rem(3px);
      color: var(--#{$prefix}app-sidebar-menu-header-color);
      font-weight: $app-sidebar-menu-header-font-weight;
      font-size: rem(11px);
      line-height: rem(20px);
    }
    & .menu-divider {
      margin: $app-sidebar-component-padding-y 0;
      background-color: var(--#{$prefix}app-sidebar-menu-divider-bg);
      height: 1px;
    }
    & .menu-caret {
      display: block;
      border: none;
      width: rem(20px);
      font-size: $font-size-sm;
      text-align: center;

      @include fontawesome();
      @if $enable-rtl {
        margin-right: auto;
      } @else {
        margin-left: auto;
      }

      &:before {
        display: block;
        opacity: 0.5;
        transition: all 0.2s linear;
        content: "\f054";
        text-align: center;

        @if $enable-rtl {
          content: "\f053";
        }
      }
    }
    & .menu-search {
      padding: $app-sidebar-component-padding-x;

      & + .menu-item {
        padding-top: 0;
      }
      & .form-control {
        border: none;
        border-bottom: 1px solid
          rgba(var(--#{$prefix}app-sidebar-component-color-rgb), 0.2);
        border-radius: 0;
        background: none;
        padding-right: 0;
        padding-left: 0;
        color: var(--#{$prefix}app-sidebar-component-color);

        &:focus {
          box-shadow: none;
          border-color: rgba(
            var(--#{$prefix}app-sidebar-component-color-rgb),
            0.5
          );
        }
      }
    }

    & .active:not(.closed):not(.closing),
    & .expanding,
    & .expand {
      & > a {
        & .menu-caret {
          &:before {
            opacity: 1;

            @if $enable-rtl {
              transform: rotate(-90deg);
            } @else {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
    & .menu-item {
      position: relative;

      & .menu-link {
        display: flex;
        align-items: center;
        padding: $app-sidebar-component-padding-y
          $app-sidebar-component-padding-x;
        color: var(--#{$prefix}app-sidebar-menu-link-color);
        font-weight: var(--#{$prefix}app-sidebar-menu-link-font-weight);
        line-height: $app-sidebar-menu-link-line-height;
        text-decoration: none;

        & .menu-text {
          flex: 1;
        }
        & .menu-badge {
          border-radius: $border-radius-lg * 2;
          background: var(--#{$prefix}app-sidebar-menu-badge-bg);
          padding: 0 $badge-padding-x * 1.5;
          color: var(--#{$prefix}app-sidebar-menu-badge-color);
          font-weight: var(--#{$prefix}app-sidebar-menu-badge-font-weight);
          font-size: rem(10px);
        }
        & .menu-label {
          vertical-align: baseline;
          border-radius: $border-radius;
          background-color: var(--#{$prefix}app-theme);
          padding: $badge-padding-y $badge-padding-x;
          color: var(--#{$prefix}app-theme-color);
          font-weight: $font-weight-bold;
          font-size: 75%;
          text-align: center;
          white-space: nowrap;

          @include gradient-enabled {
            background-image: var(--#{$prefix}gradient);
          }
          @if $enable-rtl {
            margin-right: rem(5px);
          } @else {
            margin-left: rem(5px);
          }
        }
        &:hover,
        &:focus {
          background: none;
          color: var(--#{$prefix}app-sidebar-menu-link-hover-color);
        }
      }
      & .menu-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: $app-sidebar-menu-icon-margin-y;
        margin-bottom: $app-sidebar-menu-icon-margin-y;
        border-radius: $app-sidebar-menu-icon-border-radius;
        width: $app-sidebar-menu-icon-width;
        height: $app-sidebar-menu-icon-height;
        color: var(--#{$prefix}app-sidebar-menu-icon-color);
        font-size: $app-sidebar-menu-icon-font-size;

        @if $enable-rtl {
          margin-left: $app-sidebar-menu-icon-margin-x;
        } @else {
          margin-right: $app-sidebar-menu-icon-margin-x;
        }
        & img {
          max-width: 100%;
          max-height: 100%;
        }
        & .iconify,
        & [class^="ion-"],
        & ion-icon {
          margin: $app-sidebar-menu-icon-lg-margin-y
            $app-sidebar-menu-icon-lg-margin-x;
          width: $app-sidebar-menu-icon-lg-width;
          height: $app-sidebar-menu-icon-lg-height;
          font-size: $app-sidebar-menu-icon-lg-font-size;
        }
      }
      & .menu-icon-img {
        display: flex;
        align-items: center;
        margin-top: rem(3px);
        margin-bottom: rem(3px);
        border-radius: $border-radius;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: rem(14px);
        height: rem(14px);
        overflow: hidden;

        @if $enable-rtl {
          margin-left: rem(10px);
        } @else {
          margin-right: rem(10px);
        }

        & img {
          display: block;
          margin: 0 auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
      & .menu-submenu {
        display: none;
        position: relative;
        margin: 0;
        background: var(--#{$prefix}app-sidebar-menu-submenu-bg);
        padding: rem(5px) 0 rem(10px) rem(39px);

        @if $enable-rtl {
          padding-right: rem(39px);
          padding-left: 0;
        }

        & .menu-item {
          & .menu-link {
            position: relative;
            padding: rem(3px) rem(20px) rem(3px) rem(15px);
            color: var(--#{$prefix}app-sidebar-menu-submenu-link-color);
            font-weight: var(
              --#{$prefix}app-sidebar-menu-submenu-link-font-weight
            );
            line-height: rem(18px);
            text-decoration: none;

            @if $enable-rtl {
              padding-right: rem(15px);
              padding-left: rem(20px);
            }

            &:hover,
            &:focus {
              background: none;
              color: var(--#{$prefix}app-sidebar-menu-submenu-link-hover-color);
            }

            &:after {
              position: absolute;
              top: rem(11px);
              left: rem(-11px);
              background: var(--#{$prefix}app-sidebar-menu-grid-bg);
              width: rem(11px);
              height: rem(2px);
              content: "";

              @if $enable-rtl {
                right: rem(-11px);
                left: auto;
              }
            }
          }

          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: rem(-13px);
            background: var(--#{$prefix}app-sidebar-menu-grid-bg);
            width: rem(2px);
            content: "";

            @if $enable-rtl {
              right: rem(-13px);
              left: auto;
            }
          }
          &:after {
            position: absolute;
            top: rem(11px);
            left: 0;
            z-index: 10;
            margin-top: rem(-2px);
            border: var(--#{$prefix}app-sidebar-menu-grid-border-width) solid
              var(--#{$prefix}app-sidebar-menu-grid-border-color);
            border-radius: rem(4px);
            background: var(--#{$prefix}app-sidebar-bg);
            width: rem(6px);
            height: rem(6px);
            content: "";

            @if $enable-rtl {
              right: 0;
              left: auto;
            }
          }
          &:first-child {
            &:before {
              top: rem(-14px);
            }
            &:last-child:before {
              height: rem(27px);
            }
          }
          &:last-child {
            &:before {
              bottom: auto;
              height: rem(13px);
            }
          }
          &.has-sub {
            & > a {
              &:before {
                background: var(--#{$prefix}app-sidebar-component-active-bg);
              }
            }
          }
          &.active {
            & > .menu-link {
              background: none;
              color: var(--#{$prefix}app-sidebar-component-color);
            }
            &:after {
              border-color: var(--#{$prefix}app-theme);
            }
          }

          & .menu-submenu {
            background: none;
            padding: 0 0 0 rem(15px);

            @if $enable-rtl {
              padding-right: rem(15px);
              padding-left: 0;
            }
          }
        }
      }

      &.active {
        &.has-sub {
          & > .menu-submenu {
            display: block;
          }
        }
        & > .menu-link {
          position: relative;
          z-index: 10;
          background-color: var(--#{$prefix}app-sidebar-component-active-bg);
          color: var(--#{$prefix}app-sidebar-component-active-color);

          @include gradient-enabled {
            background-image: var(--#{$prefix}gradient);
            background-color: var(--#{$prefix}app-theme);
            color: var(--#{$prefix}app-theme-color);
          }

          & .menu-icon {
            color: var(--#{$prefix}app-theme);

            @include gradient-enabled {
              color: rgba($black, 0.65);
            }
          }
          & .menu-caret {
            @include gradient-enabled {
              color: rgba($black, 0.5);
            }
          }
          & .menu-badge {
            background: rgba($black, 0.65);
          }
          & .menu-label {
            @include gradient-enabled {
              background: rgba($black, 0.65);
              color: $white;
            }
          }
        }
      }
    }
  }

  & .app-sidebar-content {
    position: relative;
  }
  & .app-sidebar-minify-btn {
    margin: rem(10px) 0;
    background: var(--#{$prefix}app-sidebar-minify-btn-bg);
    padding: rem(5px) rem(20px) rem(5px) rem(10px) !important;
    color: var(--#{$prefix}app-sidebar-component-color);

    @if $enable-rtl {
      border-radius: 0 $border-radius * 5 $border-radius * 5 0;
    } @else {
      border-radius: $border-radius * 5 0 0 $border-radius * 5;
    }

    & i {
      margin: 0 !important;
      color: var(--#{$prefix}app-sidebar-component-color);
    }
    &:hover,
    &:focus {
      background: var(--#{$prefix}app-sidebar-minify-btn-hover-bg);
    }
  }
  &.app-sidebar-grid {
    & .menu {
      & > .menu-item {
        & + .menu-item {
          margin-top: -1px;
        }
        & > .menu-link {
          border-top: 1px solid var(--#{$prefix}app-sidebar-grid-border-color);
          border-bottom: 1px solid
            var(--#{$prefix}app-sidebar-grid-border-color);
        }

        &.expanding,
        &.expand {
          & > a {
            border-bottom-color: transparent;
          }
        }
        &.active {
          & > .menu-link {
            border-color: var(--#{$prefix}app-sidebar-component-active-bg);
          }
        }
      }
    }
  }
  &.app-sidebar-transparent {
    background: none;

    & + .app-sidebar-bg {
      background-image: var(--#{$prefix}app-sidebar-bg-transparent);
      background-size: cover;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(var(--#{$prefix}app-sidebar-bg-rgb), 0.75);
        content: "";
      }
    }
    & .menu {
      & > .menu-item {
        &.active {
          & > .menu-link {
            background: rgba($black, 0.3);
          }
        }
      }
      & .menu-profile {
        background: rgba($black, 0.25);

        & a {
          &:hover,
          &:focus {
            background: none;
          }
        }
        & .menu-profile-cover {
          background: none;

          &.with-shadow {
            &:before {
              background: none;
            }
          }
        }
        &.active {
          background: rgba($black, 0.25);
        }
      }
    }
    & .menu-submenu {
      background: none;

      & > .menu-item {
        &:before {
          background: rgba($black, 0.45);
        }
        & > .menu-link {
          &:after {
            background: rgba($black, 0.45);
          }
        }
        &.has-sub {
          & > .menu-link {
            &:after {
              background: rgba($black, 0.45);
            }
          }
        }
      }
    }
  }
}
.app-sidebar-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: $app-sidebar-zindex - 10;
  background: var(--#{$prefix}app-sidebar-bg);
  width: $app-sidebar-width;

  @if $enable-rtl {
    right: 0;
    left: auto;
  }
  @include media-breakpoint-down(md) {
    left: -$app-sidebar-width;
    z-index: $app-header-zindex;
    padding-top: 0;

    @if $enable-rtl {
      right: -$app-sidebar-width;
      left: auto;
    }
  }
}
.app-sidebar-mobile-backdrop {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $app-sidebar-mobile-backdrop-zindex;
  margin: 0;
  outline: none;
  border: 0;
  background: var(--#{$prefix}app-sidebar-bg-mobile-backdrop);
  padding: 0;
  width: 100%;
  height: 100%;
}
