.widget-list {
  background: var(--#{$prefix}component-bg);

  & .widget-list-item {
    display: flex;
    color: var(--#{$prefix}component-color);
    text-decoration: none;

    & + .widget-list-item {
      & .widget-list-content,
      & .widget-list-action {
        border-top: 1px solid var(--#{$prefix}component-border-color);
      }
    }
    & .widget-list-media,
    & .widget-list-content,
    & .widget-list-action {
      padding: $spacer * 0.66 $spacer;

      & + div {
        @if $enable-rtl {
          padding-right: 0;
        } @else {
          padding-left: 0;
        }
      }
    }
    & .widget-list-media {
      &.icon {
        padding-top: $spacer * 0.333;
        padding-bottom: $spacer * 0.333;

        & i {
          display: block;
          border-radius: $border-radius * 2;
          width: rem(30px);
          height: rem(30px);
          font-size: rem(14px);
          line-height: rem(30px);
          text-align: center;
        }
      }
      & img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    & .widget-list-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    & .widget-list-action {
      display: flex;
      align-items: center;
    }
    & .widget-list-title {
      margin: 0;
      font-size: $font-size-base;
    }
    & .widget-list-desc {
      margin: 0;
      color: rgba(var(--#{$prefix}component-color-rgb), 0.75);
      font-size: $font-size-sm;
    }
  }
}
