@mixin fontawesome() {
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  font-family:
    Font Awesome\6 Free,
    Font Awesome\6 Pro,
    FontAwesome !important;
  text-rendering: auto;
}
