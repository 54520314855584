.app-sidebar-float-submenu-container {
  position: fixed;
  z-index: $app-sidebar-float-submenu-zindex;
  margin: 0;
  background: var(--#{$prefix}app-sidebar-float-submenu-bg);
  width: $app-sidebar-width;

  & .app-sidebar-float-submenu {
    @if $enable-rtl {
      padding: rem(9px) rem(39px) rem(9px) 0;
    } @else {
      padding: rem(9px) 0 rem(9px) rem(39px);
    }

    & .active,
    & .expanding,
    & .expand {
      & > a {
        & .menu-caret {
          &:before {
            transform: rotate(90deg);
            opacity: 1;

            @if $enable-rtl {
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
    & .menu-caret {
      display: block;
      margin-left: auto;
      border: none;
      width: rem(20px);
      font-size: $font-size-sm;
      text-align: center;

      @include fontawesome();
      @if $enable-rtl {
        margin-right: auto;
      }

      &:before {
        display: block;
        opacity: 0.5;
        transition: all 0.2s linear;
        content: "\f054";
        text-align: center;

        @if $enable-rtl {
          content: "\f053";
        }
      }
    }
    & .menu-item {
      position: relative;

      & .menu-link {
        display: flex;
        position: relative;
        align-items: flex-start;
        padding: rem(3px) rem(20px) rem(3px) rem(15px);
        color: var(--#{$prefix}app-sidebar-menu-submenu-link-color);
        font-weight: var(--#{$prefix}app-sidebar-menu-submenu-link-font-weight);
        line-height: rem(18px);
        text-decoration: none;

        @if $enable-rtl {
          padding-right: rem(15px);
          padding-left: rem(20px);
        }

        & .menu-text {
          flex: 1;
        }
        &:hover,
        &:focus {
          background: none;
          color: var(--#{$prefix}app-sidebar-menu-submenu-link-hover-color);
        }
        &:after {
          position: absolute;
          top: rem(11px);
          background: var(
            --#{$prefix}app-sidebar-float-submenu-grid-border-color
          );
          width: rem(11px);
          height: rem(2px);
          content: "";

          @if $enable-rtl {
            right: rem(-11px);
          } @else {
            left: rem(-11px);
          }
        }
      }
      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: rem(-13px);
        background: var(
          --#{$prefix}app-sidebar-float-submenu-grid-border-color
        );
        width: rem(2px);
        content: "";

        @if $enable-rtl {
          right: rem(-13px);
          left: auto;
        }
      }
      &:after {
        position: absolute;
        top: rem(11px);
        left: 0;
        z-index: 10;
        margin-top: rem(-2px);
        border: var(--#{$prefix}app-sidebar-menu-grid-border-width) solid
          var(--#{$prefix}app-sidebar-menu-grid-border-color);
        border-radius: rem(4px);
        background: var(--#{$prefix}app-sidebar-bg);
        width: rem(6px);
        height: rem(6px);
        content: "";

        @if $enable-rtl {
          right: 0;
          left: auto;
        }
      }
      &:first-child {
        &:before {
          top: rem(-11px);
        }
        &:last-child:before {
          height: rem(20px);
        }
      }
      &:last-child {
        &:before {
          bottom: auto;
          height: rem(13px);
        }
      }
      &.has-sub {
        & > a {
          &:before {
            background: var(
              --#{$prefix}app-sidebar-float-submenu-grid-border-color
            );
          }
        }
      }
      &.active {
        & > .menu-link {
          background: none;
          color: var(--#{$prefix}app-sidebar-component-color);
        }
        &:after {
          border-color: var(--#{$prefix}app-theme);
        }
      }

      & .menu-submenu {
        display: none;
        position: relative;
        background: none;
        padding: 0 0 0 rem(15px);

        @if $enable-rtl {
          padding-right: rem(15px);
          padding-left: 0;
        }
      }

      &.active {
        &.has-sub {
          & > .menu-submenu {
            display: block;
          }
        }
      }
    }
    & > .menu-item {
      &:first-child {
        &:before {
          top: rem(11px);
        }
        &:last-child {
          &:before {
            height: 0 !important;
          }
        }
      }
    }
  }
  & .app-sidebar-float-submenu-arrow {
    position: absolute;
    top: rem(20px);
    background: var(--#{$prefix}app-sidebar-float-submenu-grid-border-color);
    width: rem(28px);
    height: rem(2px);

    @if $enable-rtl {
      right: 0;
    } @else {
      left: 0;
    }
  }
  & .app-sidebar-float-submenu-line {
    position: absolute;
    top: rem(20px);
    background: var(--#{$prefix}app-sidebar-float-submenu-grid-border-color);
    width: rem(2px);

    @if $enable-rtl {
      right: rem(26px);
    } @else {
      left: rem(26px);
    }
  }
}
