.widget-input-container {
  display: flex;
  align-items: center;

  & .widget-input-icon {
    padding: 0 $spacer * 0.333;

    & a {
      display: block;
      padding: $spacer * 0.66;
      font-size: rem(18px);
    }
  }
  & .widget-input-box {
    flex: 1;
    padding: $spacer * 0.4 0;

    & .form-control {
      border-color: var(--#{$prefix}light);
      border-radius: $border-radius * 10;
      background: var(--#{$prefix}light);

      &:focus {
        box-shadow: none;
      }
    }
  }
  & .widget-input-divider {
    width: rem(1px);

    &:after {
      display: block;
      background: var(--#{$prefix}component-border-color);
      width: rem(1px);
      height: rem(26px);
      content: "";
    }
  }
}
