.bootstrap-timepicker {
  & .input-group-addon {
    & i {
      body & {
        display: inline;
        width: auto;
        height: auto;
      }
    }
  }
}

.bootstrap-timepicker-widget {
  & table {
    & tbody {
      & tr {
        & td {
          & .bootstrap-timepicker-hour,
          & .bootstrap-timepicker-minute,
          & .bootstrap-timepicker-meridian {
            transition: all 0.2s linear;
            box-shadow: none;
            border: 1px solid var(--#{$prefix}component-dropdown-border-color);
            border-radius: $input-border-radius;
            background: transparent;
            padding: rem(6px);
            width: rem(40px);
            height: $input-height;
            color: var(--#{$prefix}component-color);
            font-weight: $input-font-weight;

            &:focus {
              outline: none;
              box-shadow: $input-focus-box-shadow;
              border-color: $input-focus-border-color;
            }
          }
          & .glyphicon {
            @include fontawesome();

            &.glyphicon-chevron-up {
              &:before {
                content: "\f077";
              }
            }
            &.glyphicon-chevron-down {
              &:before {
                content: "\f078";
              }
            }
          }
          & a {
            border: none;
            border-radius: $border-radius !important;
            padding: $spacer * 0.5;
            color: $gray-500;
            text-decoration: none;

            &:hover,
            &:focus {
              background: var(--#{$prefix}component-dropdown-hover-bg);
              color: var(--#{$prefix}component-hover-color);
            }
          }

          &.separator {
            padding: 0 $spacer * 0.5;
          }
        }
      }
    }
  }
  &:before {
    display: none !important;
  }
  &:after {
    top: -$spacer * 0.5 !important;
    left: $spacer !important;
    border-width: $spacer * 0.5 !important;
    border-bottom-color: var(--#{$prefix}component-dropdown-bg) !important;
  }
  &.dropdown-menu {
    box-shadow: $box-shadow;
    border-radius: $border-radius-lg !important;
    padding: $spacer * 0.5 !important;
  }
  &.timepicker-orient-bottom {
    &:after {
      top: auto !important;
      bottom: -$spacer * 0.5;
      border-top-color: var(--#{$prefix}component-dropdown-bg) !important;
    }
  }
}
