body {
  & .picker {
    display: block;
    border: $form-select-border-width solid
      var(--#{$prefix}component-border-color);
    border-radius: $border-radius;
    background-color: var(--#{$prefix}component-bg);
    padding: $form-select-padding-y $form-select-padding-x;
    min-height: $input-height;
    font-family: $form-select-font-family;

    & > .pc-element {
      margin: 0 $form-select-padding-x * 0.5 $form-select-padding-y 0;
      border: none;
      border-radius: 30px;
      background: #{rgba(var(--#{$prefix}component-border-color-rgb), 0.65)};
      padding: rem(2px) rem(10px) !important;
      color: var(--#{$prefix}component-color);
      font-weight: $input-font-weight;

      &:hover {
        background: #{rgba(var(--#{$prefix}component-border-color-rgb), 1)};
        color: var(--#{$prefix}component-color);
      }

      & .pc-close {
        position: relative;
        top: 0;
        right: 0;
        opacity: 0.25;
        margin: 0;
        margin-left: $spacer * 0.5;
        color: inherit;
        font-size: $font-size-base;

        @include fontawesome();

        &:after {
          content: "\f00d" !important;
        }
        &:hover {
          opacity: 0.5;
        }
      }
      & + .pc-select {
        padding-top: $form-select-padding-y;

        & .pc-element {
          margin-top: -$form-select-padding-y;
        }
      }
    }

    & .pc-select {
      display: block;
      margin: (-$form-select-padding-y) (-$form-select-padding-x);
      width: auto;
      min-width: initial;
      max-width: initial;

      & .pc-element {
        display: block;
        margin: 0;
        @include font-size($form-select-font-size);
        appearance: none;
        border: none;
        background-image: escape-svg($form-select-indicator);
        background-size: $form-select-bg-size;
        background-repeat: no-repeat;
        background-color: transparent;
        color: $form-select-color;
        font-weight: $form-select-font-weight;
        line-height: $form-select-line-height;

        @if $enable-rtl {
          background-position: left 0.75rem center;
          padding: $form-select-padding-y
            $form-select-padding-x
            $form-select-padding-y
            $form-select-indicator-padding;
        } @else {
          background-position: $form-select-bg-position;
          padding: $form-select-padding-y
            $form-select-indicator-padding
            $form-select-padding-y
            $form-select-padding-x;
        }
      }
      & .pc-list {
        top: 100%;
        box-shadow: $box-shadow;
        border: none;
        border-radius: $border-radius;
        background: var(--#{$prefix}component-dropdown-bg);
        padding: $dropdown-padding-y $dropdown-padding-x;

        & input[type="search"] {
          display: block;
          margin: 0 rem(10px) rem(5px);
          padding: $input-padding-y $input-padding-x;
          width: calc(100% - 20px);
          font-family: $input-font-family;
          @include font-size($input-font-size);
          appearance: none;
          transition: $input-transition;
          border: $input-border-width solid
            var(--#{$prefix}component-border-color);
          border-radius: $border-radius;
          background-clip: padding-box;
          background-color: var(--#{$prefix}component-bg);
          color: var(--#{$prefix}component-color);
          font-weight: $input-font-weight;
          line-height: $input-line-height;

          &:focus {
            outline: 0;
            box-shadow: $input-focus-box-shadow;
            border-color: $input-focus-border-color;
            background-color: $input-focus-bg;
            color: $input-focus-color;
          }
        }

        & ul {
          & > li {
            background: none;
            padding: $nav-link-padding-y $nav-link-padding-x;
            color: var(--#{$prefix}component-color);
            font-weight: $input-font-weight;

            &:nth-child(even) {
              background: none;
            }
            &:hover {
              background: var(--#{$prefix}component-dropdown-hover-bg);
              color: var(--#{$prefix}component-hover-color);
            }
          }
        }
      }
    }
  }
}
