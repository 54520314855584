.datepicker {
  body & {
    border-radius: $border-radius-lg;
    color: var(--#{$prefix}component-color);

    @if $enable-rtl {
      direction: rtl;
    }

    & table {
      width: 100%;
    }
    & .datepicker-days,
    & .datepicker-months,
    & .datepicker-years,
    & .datepicker-decades,
    & .datepicker-centuries {
      & table {
        & thead {
          & tr {
            & th {
              color: var(--#{$prefix}component-color);

              &.prev,
              &.next,
              &.datepicker-switch {
                border-radius: $border-radius;
                padding: $spacer * 0.5;
                line-height: $line-height-base;

                &:hover,
                &:focus {
                  background: var(--#{$prefix}component-hover-bg);

                  &:before {
                    color: var(--#{$prefix}component-color);
                  }
                }
              }
              &.prev,
              &.next {
                position: relative;
                width: 15%;
                color: transparent;

                @include fontawesome();

                &:before {
                  position: absolute;
                  top: 50%;
                  margin-top: -$font-size-lg * 0.5;
                  height: $font-size-lg;
                  color: $gray-500;
                  font-size: $font-size-lg;
                  line-height: 1;
                }
                & i {
                  display: none;
                }
              }
              &.prev {
                &:before {
                  content: "\f104";
                }
              }
              &.next {
                &:before {
                  content: "\f105";
                }
              }
              &.datepicker-switch {
                width: auto;
              }
              &.dow {
                padding: $spacer * 0.5 0;
              }
            }
          }
        }
        & tbody {
          & tr {
            & td {
              padding: $spacer * 0.5;

              & span {
                &.decade,
                &.year,
                &.month,
                &.century {
                  border-radius: $border-radius;
                  color: $gray-600;
                  font-weight: $font-weight-bold;
                  text-shadow: none;

                  &:hover,
                  &:focus {
                    background: var(--#{$prefix}component-hover-bg);
                  }
                  &.focused {
                    background: var(--#{$prefix}component-hover-bg);
                    color: var(--#{$prefix}component-hover-color);
                  }
                  &.active {
                    background: var(--#{$prefix}component-active-bg) !important;
                    color: var(--#{$prefix}component-active-color);
                  }
                }
              }
              &.day {
                border-radius: $border-radius;
                padding: $spacer * 0.5;
                color: rgba(var(--#{$prefix}component-color-rgb), 0.75);
                font-weight: $font-weight-bold;

                &:hover,
                &:focus,
                &.selected,
                &.highlighted,
                &.range {
                  background: var(--#{$prefix}component-hover-bg);
                  text-shadow: none;
                }
                &.today {
                  background: tint-color($component-active-bg, 75%) !important;
                  color: shade-color($component-active-bg, 75%);
                }
                &.active {
                  background: $component-active-bg !important;
                  color: $white;
                  text-shadow: none !important;
                }
                &.range {
                  border-radius: 0;
                }
                &.range-start {
                  border-radius: $border-radius 0 0 $border-radius;
                }
                &.range-end {
                  border-radius: 0 $border-radius $border-radius 0;
                }
                &.old {
                  color: var(--#{$prefix}component-disabled-color);
                }
                &.new {
                  color: var(--#{$prefix}component-disabled-color);
                }
                &.disabled {
                  color: var(--#{$prefix}component-disabled-color);

                  &:hover,
                  &:focus {
                    background: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.datepicker-inline {
      display: block;
      padding: 0;
      width: auto;
    }
    &.dropdown-menu {
      box-shadow: $box-shadow;
      border: none;
      width: rem(320px);
      min-width: rem(250px);
      font-size: $font-size-base;
      font-family: inherit;

      @include media-breakpoint-down(xs) {
        width: rem(250px);
      }

      &:before {
        display: none;
      }
      &.datepicker-orient-left {
        &:after {
          left: $spacer;
        }
      }
      &.datepicker-orient-right {
        &:after {
          right: $spacer;
        }
      }
      &.datepicker-orient-top {
        margin-bottom: $spacer * 0.5;
      }
      &.datepicker-orient-bottom {
        margin-top: $spacer * 0.5;
      }
    }
  }
  & .input-daterange {
    & .input-group-addon {
      display: flex;
      align-items: center;
      margin: 0;
      border-radius: 0;
      background: var(--#{$prefix}component-border-color);
      color: var(--#{$prefix}component-color);
      font-weight: $font-weight-bold;

      & + .form-control {
        border-radius: 0 $border-radius $border-radius 0;

        @if $enable-rtl {
          border-radius: 0;
        }
      }
    }
  }
  &.datepicker-dropdown {
    &:after {
      border-bottom-color: var(--#{$prefix}component-dropdown-bg);
    }
    &.datepicker-orient-top {
      &:after {
        border-top-color: var(--#{$prefix}component-dropdown-bg);
      }
    }
  }
}
