.registered-users-list {
  display: flex;
  flex-wrap: wrap;
  margin: rem(7.5px);
  padding: 0;
  list-style-type: none;

  & > li {
    padding: rem(7.5px);
    width: 25%;
    color: $dark;
    font-size: rem(12px);

    & > a {
      display: block;
      margin-bottom: rem(10px);
    }
    & img {
      border-radius: $border-radius;
      max-width: 100%;
    }
    & .username {
      margin-bottom: 0;
      color: var(--#{$prefix}component-color);
      font-weight: 600;
      font-size: rem(12px);

      & small {
        display: block;
        color: rgba(var(--#{$prefix}component-color-rgb), 0.5);
        font-size: rem(11px);
        line-height: rem(15px);
      }
    }
  }
}
