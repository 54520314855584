.gallery {
  margin: $spacer * -0.333;

  & .image {
    display: block;
    padding: $spacer * 0.333;
    width: 100%;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: 33.33%;
    }
    @include media-breakpoint-up(xl) {
      width: 25%;
    }

    & a {
      transition: all 0.2s linear;

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
    & img,
    & .img {
      border-radius: $border-radius $border-radius 0 0;
      width: 100%;
      height: rem(200px);
    }
    & .img {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    & .image-inner {
      position: relative;
      border-radius: $border-radius $border-radius 0 0;
      background: var(--#{$prefix}component-bg);
    }
    & .image-caption {
      position: absolute;
      top: $spacer;
      left: 0;
      margin: 0;
      background: rgba($gray-900, 0.85);
      padding: $spacer * 0.333 $spacer;
      color: $white;
      font-weight: $font-weight-bold;
    }
    & .image-info {
      border-radius: 0 0 $border-radius $border-radius;
      background: var(--#{$prefix}component-bg);
      padding: $spacer;
      color: rgba(var(--#{$prefix}component-color-rgb), 0.75);

      & .title {
        margin: 0 0 $spacer * 0.5;
        overflow: hidden;
        color: var(--#{$prefix}component-color);
        font-weight: $font-weight-bold;
        font-size: $h5-font-size;
        line-height: $headings-line-height;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & .rating {
        display: flex;
        margin: 0;

        & span {
          &.star {
            margin-right: rem(1px);

            &:before {
              content: "\f005";
              color: rgba(var(--#{$prefix}component-color-rgb), 0.15);

              @include fontawesome();
            }
            &.active {
              &:before {
                color: $orange;
              }
            }
          }
        }
      }
      & .desc {
        height: rem(51px);
        overflow: hidden;
        font-size: rem(12px);
        line-height: rem(17px);
      }
    }
  }
}
