@keyframes topMenuControlSlideInLeft {
  from {
    left: rem(-50px);
  }
  to {
    left: 0;
  }
}
@keyframes topMenuControlSlideInRight {
  from {
    right: rem(-50px);
  }
  to {
    right: 0;
  }
}
.app-top-menu {
  right: 0;
  left: 0;
  z-index: $app-top-menu-zindex;
  background: var(--#{$prefix}app-top-menu-bg);

  @include media-breakpoint-up(md) {
    position: fixed;
    top: $app-header-height;
    height: $app-top-menu-height;
  }
  @include media-breakpoint-down(md) {
    display: none;
  }

  & .menu {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: nowrap;
    }
    @include media-breakpoint-down(md) {
      display: block;
      margin: 0 !important;
    }

    & .menu-item {
      position: relative;

      & .menu-link {
        display: flex;
        align-items: center;
        padding: $app-top-menu-component-padding-y
          $app-top-menu-component-padding-x;
        color: var(--#{$prefix}app-top-menu-link-color);
        font-weight: var(--#{$prefix}app-top-menu-link-font-weight);
        text-decoration: none;
        white-space: nowrap;

        & .menu-text {
          flex: 1;
        }
        & .menu-badge {
          border-radius: $border-radius-lg * 2;
          background: var(--#{$prefix}app-top-menu-menu-badge-bg);
          padding: 0 rem(8px);
          color: var(--#{$prefix}app-top-menu-menu-badge-color);
          font-weight: var(--#{$prefix}app-top-menu-menu-badge-font-weight);
          font-size: rem(10px);

          @if $enable-rtl {
            margin-right: rem(10px);
          } @else {
            margin-left: rem(10px);
          }
        }
        & .menu-label {
          vertical-align: baseline;
          border-radius: var(--#{$prefix}border-radius);
          background-color: var(--#{$prefix}app-theme);
          padding: rem(3px) rem(6px);
          color: var(--#{$prefix}app-theme-color);
          font-weight: $font-weight-bold;
          font-size: 75%;
          text-align: center;
          white-space: nowrap;

          @include gradient-enabled {
            background-image: var(--#{$prefix}gradient);
          }
          @if $enable-rtl {
            margin-right: rem(5px);
          } @else {
            margin-left: rem(5px);
          }
        }
        &:hover,
        &:focus {
          background: var(--#{$prefix}app-top-menu-link-hover-bg);
          color: var(--#{$prefix}app-top-menu-link-hover-color);
        }
      }
      & .menu-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(14px);
        color: var(--#{$prefix}app-sidebar-menu-icon-color);
        font-size: $font-size-lg;
        line-height: rem(20px);
        text-align: center;

        @if $enable-rtl {
          margin-left: rem(10px);
        } @else {
          margin-right: rem(10px);
        }

        & img {
          max-width: 100%;
          max-height: 100%;
        }
        & .iconify,
        & [class^="ion-"],
        & ion-icon {
          display: block;
          margin-right: rem(8px);
          margin-left: rem(-2px);
          width: rem(18px);
          font-size: rem(18px);

          @if $enable-rtl {
            margin-right: rem(-2px);
            margin-left: rem(8px);
          }
        }
      }
      & .menu-icon-img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: rem(3px);
        margin-bottom: rem(3px);
        border-radius: var(--#{$prefix}border-radius);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: rem(14px);
        height: rem(14px);
        overflow: hidden;

        @if $enable-rtl {
          margin-left: rem(10px);
        } @else {
          margin-right: rem(10px);
        }

        & img {
          display: block;
          margin: 0 auto;
          max-width: 100%;
          max-height: 100%;
        }
      }

      &.active {
        & > .menu-link {
          position: relative;
          z-index: 10;
          background-color: var(--#{$prefix}app-top-menu-link-active-bg);
          color: var(--#{$prefix}app-top-menu-link-active-color);

          @include gradient-enabled {
            background-image: var(--#{$prefix}gradient);
            background-color: var(--#{$prefix}app-theme);
            color: var(--#{$prefix}app-theme-color);
          }

          & .menu-icon {
            color: var(--#{$prefix}app-theme);

            @include gradient-enabled {
              color: rgba($black, 0.65);
            }
          }
          & .menu-caret {
            @include gradient-enabled {
              color: rgba($black, 0.5);
            }
          }
          & .menu-badge {
            background: rgba($black, 0.65);
          }
          & .menu-label {
            @include gradient-enabled {
              background: rgba($black, 0.65);
              color: $white;
            }
          }
        }
        & > .menu-submenu {
          @include media-breakpoint-down(md) {
            display: block;
          }
        }
      }

      &.menu-control {
        position: absolute;
        top: 0;
        z-index: 100;
        margin: 0;

        @include media-breakpoint-down(sm) {
          display: none !important;
        }

        & > .menu-link {
          justify-content: center;
          align-items: center;
          transition: $transition-base;
          background: var(--#{$prefix}app-top-menu-control-link-bg);
          padding: 0;
          width: $app-top-menu-height;
          height: $app-top-menu-height;
          color: var(--#{$prefix}app-top-menu-control-link-color);
          text-align: center;

          & i {
            display: block;
            float: none;
            margin: 0;
            width: auto;
            font-size: rem(18px);
            line-height: rem(40px);
          }
          &:hover,
          &:focus {
            background: var(--#{$prefix}app-top-menu-control-link-hover-bg);
            color: var(--#{$prefix}app-top-menu-control-link-hover-color);
          }
        }
        &.menu-control-start {
          @if $enable-rtl {
            right: -$app-top-menu-height;
          } @else {
            left: -$app-top-menu-height;
          }

          & > .menu-link {
            @if $enable-rtl {
              box-shadow: -12px 0 10px -10px rgba($black, 0.5);
            } @else {
              box-shadow: 12px 0 10px -10px rgba($black, 0.5);
            }
          }
          &.show {
            left: 0;
            animation: topMenuControlSlideInLeft 0.2s;

            @if $enable-rtl {
              right: 0;
              left: auto;
              animation: topMenuControlSlideInRight 0.2s;
            }
          }
        }
        &.menu-control-end {
          @if $enable-rtl {
            left: -$app-top-menu-height;
          } @else {
            right: -$app-top-menu-height;
          }

          & > .menu-link {
            @if $enable-rtl {
              box-shadow: 12px 0 10px -10px rgba($black, 0.5);
            } @else {
              box-shadow: -12px 0 10px -10px rgba($black, 0.5);
            }
          }
          &.show {
            right: 0;
            animation: topMenuControlSlideInRight 0.2s;

            @if $enable-rtl {
              right: auto;
              left: 0;
              animation: topMenuControlSlideInLeft 0.2s;
            }
          }
        }
      }
    }
    & > .menu-item {
      &.has-sub {
        &:hover {
          & > .menu-submenu {
            @include media-breakpoint-up(md) {
              display: block;
            }
          }
        }
      }
    }
    & .menu-caret {
      display: block;
      margin-left: rem(5px);
      border: none;
      width: rem(20px);
      height: rem(20px);
      font-size: rem(11px);
      line-height: rem(20px);
      text-align: center;

      @include fontawesome();
      @if $enable-rtl {
        margin-right: rem(5px);
        margin-left: 0;
      }
      @include media-breakpoint-down(sm) {
        margin-left: auto;

        @if $enable-rtl {
          margin-right: auto;
          margin-left: 0;
        }
      }

      &:before {
        display: block;
        transform: rotate(90deg);
        opacity: 0.5;
        transition: all 0.2s linear;
        content: "\f054";
        text-align: center;
      }
    }
    & .menu-submenu {
      display: none;
      top: $app-top-menu-height;
      margin: 0;
      background: var(--#{$prefix}app-top-menu-menu-submenu-bg);
      padding: rem(10px) 0;
      list-style-type: none;

      @include media-breakpoint-up(md) {
        position: absolute;
        left: 0;
        width: $app-sidebar-width;
        max-height: calc(100vh - #{$app-top-menu-height + $app-header-height});

        @if $enable-rtl {
          right: 0;
          left: auto;
        }
      }

      & > .menu-item {
        & > .menu-link {
          display: flex;
          position: relative;
          align-items: center;
          padding: rem(4px) rem(20px);
          text-decoration: none;

          @include media-breakpoint-down(sm) {
            padding-left: rem(44px);

            @if $enable-rtl {
              padding-right: rem(44px);
              padding-left: rem(20px);
            }
          }

          & .menu-caret {
            margin-left: auto;

            @if $enable-rtl {
              margin-right: auto;
              margin-left: 0;
            }
          }
        }
      }
      & .menu-submenu {
        position: relative;
        top: 0;
        left: 0;
        background: var(--#{$prefix}app-top-menu-menu-submenu-submenu-bg);
        max-height: auto;
        overflow: initial;

        @if $enable-rtl {
          right: 0;
          left: auto;
        }
      }
    }
  }
}
