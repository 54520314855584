.widget-stats,
.widget.widget-stats {
  position: relative;
  border-radius: $border-radius;
  padding: $spacer;
  color: $white;

  & .stats-info {
    & h4 {
      margin: rem(5px) 0;
      color: rgba($white, 0.7);
      font-size: rem(12px);
    }
    & p {
      margin-bottom: 0;
      font-weight: $font-weight-bold;
      font-size: rem(24px);
    }
  }
  & .stats-link {
    & a {
      display: block;
      margin: $spacer $spacer * -1 $spacer * -1;
      background: rgba($black, 0.4);
      padding: $spacer * 0.5 $spacer;
      color: rgba($white, 0.7);
      text-align: right;
      text-decoration: none;

      @if $enable-rtl {
        text-align: left;
      }

      &:hover,
      &:focus {
        background: rgba($black, 0.6);
        color: $white;
      }
    }
  }
  & .stats-icon {
    position: absolute;
    top: $spacer;
    margin-left: $spacer;
    width: rem(56px);
    height: rem(56px);
    color: rgba($black, 0.15);
    font-size: rem(42px);
    line-height: rem(56px);
    text-align: center;

    @if $enable-rtl {
      right: auto;
      left: $spacer;
    } @else {
      right: $spacer;
    }

    &.stats-icon-lg {
      top: $spacer;
      opacity: 0.15;
      width: rem(128px);
      height: rem(50px);
      color: $white;
      font-size: rem(128px);
      line-height: rem(50px);
      text-shadow: rem(3px) rem(7px) rgba($black, 0.25);

      @if $enable-rtl {
        left: 0;
      } @else {
        right: 0;
      }

      & i {
        position: relative;
      }
    }
    &.stats-icon-square {
      top: $spacer;
      right: $spacer;
      opacity: 1;
      margin-left: $spacer;
      border-radius: $border-radius * 4;
      width: rem(56px);
      height: rem(56px);
      font-size: rem(42px);
      line-height: rem(56px);
      text-align: center;

      @if $enable-rtl {
        right: auto;
        left: $spacer;
      }
    }
  }
  & .stats-content {
    position: relative;
  }
  & .stats-title {
    position: relative;
    margin: 0 0 rem(2px);
    color: rgba($white, 0.7);
    font-weight: $font-weight-bold;
    font-size: rem(12px);
  }
  & .stats-desc {
    margin: rem(-5px) 0 rem(-3px);
    color: rgba($white, 0.7);
  }
  & .stats-number {
    margin-bottom: $spacer;
    font-weight: $font-weight-bold;
    font-size: rem(24px);
    letter-spacing: rem(1px);
  }
  & .stats-progress {
    margin: 0 0 $spacer;
    border-radius: $border-radius;
    background: rgba($black, 0.5);
    height: rem(2px);

    & .progress-bar {
      border-radius: 0 $border-radius $border-radius 0;
      background: $white;
    }
    &.stats-progress-light {
      background: rgba($white, 0.3);
    }
  }
  &.bg-white {
    & .stats-progress {
      background: rgba($black, 0.1);

      & .progress-bar {
        background: $dark;
      }
    }
  }
}
