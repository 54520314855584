.app-with-top-menu {
  @include media-breakpoint-up(md) {
    padding-top: $app-header-height + $app-top-menu-height;
  }

  & .app-sidebar {
    @include media-breakpoint-up(md) {
      padding-top: $app-header-height + $app-top-menu-height;
    }
  }
  &.has-scroll {
    & .app-header {
      @include media-breakpoint-up(md) {
        box-shadow: none;
      }
    }
    & .app-top-menu {
      @include media-breakpoint-up(md) {
        box-shadow: $app-header-box-shadow-scroll;
      }
    }
  }
  &.app-header-fixed {
    @include media-breakpoint-up(md) {
      padding-top: $app-header-height + $app-top-menu-height;
    }
  }
}
